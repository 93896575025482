
import { defineComponent, PropType } from "vue";
import { SynopsisCardItem } from "@/interfaces/SynopsisCardItem";
export default defineComponent({
  name: "SynopsisCard",
  props: {
    title: {type: String, default: ""},
    items: {type: Array as PropType<Array<SynopsisCardItem>>, required: true}
  },
});
