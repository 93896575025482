
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { PropType, defineComponent } from "vue";
import { insuranceStore } from "@/stores/InsuranceStore";
import { routerStore } from "@/stores/RouterStore";
import { popupStore } from "@/stores/PopupStore";
import TextInput from '@/components/text-input/TextInput.vue';
import { mapStores } from "pinia";
import { mapActions, mapState } from "pinia";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import InsuranceClient from "@/rest-client/InsuranceClient";

export default defineComponent({
  name: "ManualUnderwritingTab",
  components: {
    LoadingSpinnerWithBackground,
    TextInput
  },
  props: {
    screenWidth: {type: String as PropType<ScreenWidth>, required: true}
  },
  data() {
    return {
      errors: {} as Record<string, any>,
      previousPath: null as string | null,
      isLoading: false,
      additionalDescription: "",
      selectedFiles: [] as File[]
    };
  },
  computed: {
    ...mapStores(insuranceStore),
    ...mapState(insuranceStore, ["policyInfo"]),
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    }
  },
  created() {
    this.previousPath = this.$route.fullPath.replace("ManualUnderwriting", "ProductAmendments");
  },
  methods: {
    ...mapActions(routerStore, ["getPage"]),
    ...mapActions(popupStore, ['showErrorMsg']),
    readError(field: string) {
      if (!this.errors) {
        return undefined;
      }
      return this.errors[field];
    },
    validate() {
      this.errors = {};

      if (!this.additionalDescription) {
        this.errors['additionalDescription'] = 'Answer required';
      }

      return this.errors && Object.keys(this.errors).length == 0;
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;

      if (this.additionalDescription.length <= 0 || this.additionalDescription.length > 1000) {
        this.showErrorMsg('The text length should be between 1 ~ 1000.');
        this.isLoading = false;
        return;
      }

      if(this.selectedFiles.length > 0) {
        const formData = new FormData();
        for(let i = 0; i < this.selectedFiles.length; i++ ) {
          let file = this.selectedFiles[i];
          formData.append('file', file);
        }

        var isUploaded = await this.uploadFiles(formData);

        if (!isUploaded) {
          this.showErrorMsg('Error happened while submitting data.');
          this.isLoading = false;
          return;
        }
      }

      var isNoteUpdated = await this.updateNote();
      if (!isNoteUpdated) {
        this.showErrorMsg('Error happened while submitting data.');
        this.isLoading = false;
        return;
      }

      const nextPath = this.$route.fullPath.replace("ManualUnderwriting", "ProductAmendments");
      this.$router.push(nextPath);
    },
    async updateNote() {
      const notesResponse = await InsuranceClient.submitManualUnderwriterNote(this.additionalDescription);

      if (notesResponse?.statusCode != 200) {
        return false;
     }

     return true;
    },
    async uploadFiles(formData: FormData) {
      let response;
      for(let i = 0; i < this.selectedFiles.length; i++ ) {
        response = await InsuranceClient.uploadReferralSupportingDocs({
          productType: this.policyInfo.policy.policyType == '0'? 'CLI': 'SSLI',
          file: this.selectedFiles[i],
          formData
        });
      }

      if (response && response?.statusCode != 200) {
        return false;
      }

      return true;
    },
    triggerFileInput() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.click();
    },
    handleFileChange(event: Event) {
      const target = event.target as HTMLInputElement;
      if (!target.files) {
        return;
      }
      const files = [...target.files];

      // Check maximum file size for each file (5MB)
      const maxSizeInBytes = 5 * 1024 * 1024;
      const invalidFiles = files.filter(file => file.size > maxSizeInBytes);

      if (invalidFiles.length > 0) {
        this.showErrorMsg("Maximum file size allowed is 5MB for each file.");
        return;
      }

      const existingFile = this.selectedFiles.find(file => files.find(f => f.name == file.name));
      if (existingFile) {
        this.showErrorMsg("This file has already been added.");
        return;
      }

      // Check maximum number of uploaded files
      if (this.selectedFiles.length + files.length > 25) {
        this.showErrorMsg("Maximum number of files allowed is 25.");
        return;
      }

      this.selectedFiles = this.selectedFiles.concat(files);
    }
  },
});
