import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import { InsuranceCover } from "../interfaces/productQuote/InsuranceCover";
import { CalculatedBenefitPremium } from "../interfaces/productQuote/CalculatedBenefitPremium";
import InsuranceClient from "@/rest-client/InsuranceClient";
import { insuranceStore } from "@/stores/InsuranceStore";
import { popupStore } from "@/stores/PopupStore";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";
import SumInsuredMap from "@/utils/SumInsuredMap";

export default class ProductQuoteDataGenerator {
  public static financialStrengthText = {
    title: "Financial strength rating",
    content:
      'In accordance with Regulation 11 of the Insurance (Prudential Supervision) Regulations 2010, <a href="https://www.booster.co.nz/booster-kiwisaver-scheme/super-simple-life-insurance/disclosure.aspx" target="_blank">Booster Assurance Limited<a/>, as a licenced insurer, is not required to have a current financial strength rating because its annual gross premium income for the most recently completed accounting period (from 1 July to 30 June) is less than $1.5 million.',
  };

  public static exclusionText = {
    title: "What is an ‘Exclusion’?",
    content:
      "If the risk of a claim is high enough, the risk effectively becomes uninsurable. When that happens, Booster may need to remove the uninsurable risk by removing the associated condition from the cover entirely. This means, for that particular condition, no claim will be payable. Exclusions can also apply to dangerous occupations – or any hobbies and activities that could increase your chances of being injured",
  };

  public static loadingText = {
    title: "What does ‘Loading’ mean?",
    content:
      "A loading is the amount a higher-risk applicant’s premium will be increased by, over and above Booster’s standard premium rate. This increase reflects the higher chance that the applicant will make a claim in the future.",
  };

  public static approvedStatusList: string[] = [
    "Approved",
    "ApprovedWithExclusion",
    "ApprovedWithLoading",
    "ApprovedWithLoadingAndExclusion",
    "ApprovedWithLoadingAndExclusionAndPostponement",
    "ApprovedWithLoadingAndPostponement",
    "ApprovedWithPostponement",
    "ApprovedWithPostponementAndExclusion",
  ];

  public static referredStatusListCLI: string[] = ["Referred", "Declined"];
  public static referredStatusListSSLI: string[] = ["Referred"];
  async getPolicySchedule() {
    const policyResponse = await InsuranceClient.getPolicySchedule();

    if (policyResponse.statusCode !== 200) {
      this.showAlert("Please check your network. Cannot fetch crucial data.");
      return;
    }

    return policyResponse.body;
  }

  public async getBenefitPremiums() {
    const store = insuranceStore();

    const benefitPremiums = [] as Array<CalculatedBenefitPremium>;
    for (const policyBenefit of store.policyInfo.policy.policyBenefits) {
      if (policyBenefit.benefitStatus != BenefitStatus.Initialized) {
        return;
      }

      const key = SumInsuredMap.mapToSumInsured(
        policyBenefit.benefit.benefitCode
      ) as keyof typeof store.policyInfo.sumInsured;
      const coverAmount = store.policyInfo.sumInsured[key];

      const request = {
        gender: store.policyInfo.insuredPerson.gender,
        isSmoker: store.policyInfo.insuredPerson.smokerStatus === "Smoker",
        benefitCode: policyBenefit.benefit.benefitCode,
        coverAmount: coverAmount,
        policyRefId: store.policyInfo.policyRefId,
      };

      const benefitPremiumResponse = await InsuranceClient.calculateBenefitPremiums(request);

      if (benefitPremiumResponse.statusCode !== 200) {
        if (benefitPremiumResponse.statusCode == 404) {
          return [] as Array<CalculatedBenefitPremium>;
        }

        this.showAlert(
          "Premium couldn't be calculated. Please check your network and try again later."
        );
        return;
      }

      benefitPremiums.push(benefitPremiumResponse.body);
    }

    return benefitPremiums;
  }

  public getInsuranceCovers(
    policyProducts: any,
    benefitPremiums: CalculatedBenefitPremium[],
    currentPolicyBenefits: PolicyBenefit[] | undefined,
    policyType: string
  ) {
    const products: InsuranceCover[] = [];
    policyProducts.forEach(async (policyProduct: { profileName: string; sumAssured: number }) => {
      const benefitPremium = benefitPremiums.find(
        (x: CalculatedBenefitPremium) =>
          x.benefitCode == policyProduct.profileName && x.productType == policyType
      );
      if (benefitPremium == undefined) {
        return;
      }

      if (policyProduct.sumAssured == 0) {
        return;
      }

      let coverAmount =
        policyProduct.sumAssured > benefitPremium.coverMaximumAmount
          ? benefitPremium.coverMaximumAmount
          : policyProduct.sumAssured;

      const currentPolicyBenefit = currentPolicyBenefits?.find(
        (x) => x.benefitId == benefitPremium.benefitId
      );

      if (currentPolicyBenefit == undefined) {
        return;
      }

      if (currentPolicyBenefit.coverAmount > 0) {
        //Set saved cover amount
        coverAmount = currentPolicyBenefit.coverAmount;
      }
      const content: string[] = [];
      const amendments: string[] = [];
      const loadingPercentage = benefitPremium.loadingPercentage;
      const loadingRate = this.getLoadingRate(loadingPercentage);
      const loadingAmount = benefitPremium.loadingAmount;

      if (loadingPercentage > 0) {
        this.setLoadingContent(content, amendments, policyProduct);
      }

      if (loadingAmount > 0) {
        this.setLoadingAmountContent(content, amendments, policyProduct);
      }

      const isWithExclusion = this.validateProductForExclusion(content, amendments, policyProduct);

      const item: InsuranceCover = {
        benefitPremium: benefitPremium,
        name: benefitPremium.benefitName,
        benefitCode: policyProduct.profileName,
        coverAmount: coverAmount,
        monthlyCost: benefitPremium.monthlyPremium,
        content: content,
        loadingRate: loadingRate,
        loadingPercentage: loadingPercentage,
        loadingAmount: loadingAmount,
        isWithExclusion: isWithExclusion,
        amendments: amendments.length > 0 ? amendments : undefined,
        maxCover: benefitPremium.coverMaximumAmount, // Set this as the Booster max amount for now, compare to the Quirc max amount in ProductQuoteTab
      };
      products.push(item);
    });

    return products;
  }

  private setLoadingContent(content: string[], amendments: string[], product: any) {
    product.premiums
      .filter((premium: any) => premium.percentageLoading > 0)
      .map(
        (premium: any): string =>
          `Loading of ${premium.percentageLoading}% due to ${premium.profileName}`
      )
      .forEach((amendment: any) => amendments.push(amendment));

    const loadingContent =
      "Based on the answers provided, your application has been approved with a <b>loading of </b>";
    const loadings: string[] = [];
    product.premiums.forEach((premium: any) => {
      if (premium.percentageLoading > 0) {
        loadings.push(
          "<b>" + premium.percentageLoading + "% due to " + premium.profileName + "</b>"
        );
      }
    });

    content.push(loadingContent + loadings.join(" and ") + ".");
  }

  private setLoadingAmountContent(content: string[], amendments: string[], product: any) {
    product.premiums
      .filter((premium: any) => premium.unitLoading > 0)
      .map(
        (premium: any): string =>
          `$${premium.unitLoading} per thousand dollars of limit due to ${premium.profileName}`
      )
      .forEach((amendment: any) => amendments.push(amendment));

    const loadingContent =
      "Based on the answers provided, your application has been approved with a";
    const loadings: string[] = [];
    product.premiums.forEach((premium: any) => {
      if (premium.unitLoading > 0) {
        loadings.push(
          " <b>$" +
            premium.unitLoading +
            " per thousand dollars of limit due to " +
            premium.profileName +
            "</b>"
        );
      }
    });

    content.push(loadingContent + loadings.join(" and ") + ".");
  }

  private validateProductForExclusion(content: string[], amendments: string[], product: any) {
    let isWithExclusion = false;

    product.productReferences
      .filter((productRef: any) => productRef.typeName === "Exclusions")
      .map((productRef: any): string => `Exclusion for ${productRef.title}`)
      .forEach((amendment: any) => amendments.push(amendment));

    product.productReferences
      .filter((productRef: any) => productRef.typeName === "Loading term")
      .map((productRef: any): string => productRef.reference)
      .forEach((amendment: any) => amendments.push(amendment));

    const exclusionText =
      "Based on the answers provided, your application has been approved with an <b> Exclusion for ";
    product.productReferences.forEach((productRef: any) => {
      if (productRef.typeName === "Exclusions") {
        content.push(exclusionText + productRef.title + ". </b>" + productRef.reference + ".");
        isWithExclusion = true;
      }
      const loadingTermText =
        "Based on the answers provided, your application has been approved with <b> ";
      if (productRef.typeName === "Loading term") {
        content.push(loadingTermText + productRef.reference + ".");
      }
    });

    return isWithExclusion;
  }

  private getLoadingRate(loadingPercentage: number) {
    const premiumRate = loadingPercentage / 100;
    return 1 + premiumRate;
  }

  private showAlert(msg: string) {
    const popupBox = popupStore();
    popupBox.showErrorMsg(msg);
  }
}
