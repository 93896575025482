
import { defineComponent } from "vue";
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue'

export default defineComponent({
  name: "LoadingSpinnerWithBackground",
  components: {
    LoadingSpinner
  }
})
