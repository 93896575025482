
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore";
import { popupStore } from "@/stores/PopupStore";
import InsuranceClient from "@/rest-client/InsuranceClient";

export default defineComponent({
  name: "CliConfirmationTab",
  props: {
    isPolicyReferred: {type: Boolean, default: false}
  },
  data() {
    return {
      seperateBankHolder: false,
      policyNumber:'',
    }
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
  },
  async mounted() {
    const popupBox = popupStore();
    const consentQuestionsResult = await InsuranceClient.getConsentQuestions(
      "InsuranceWebPayment"
    );
    if (consentQuestionsResult.statusCode != 200) {
      popupBox.showErrorMsg(
        "We cannot find your consent question record. Please try again or contact us."
      );
      throw Error("Auth Error. Abort.");
    }
    this.seperateBankHolder = consentQuestionsResult.body[0].value == "No";

    const policyNumberResult = await InsuranceClient.getInsurancePolicyNumber();

    if (policyNumberResult.statusCode != 200) {
      popupBox.showErrorMsg(
        "We cannot find policy number. Please try again or contact us."
      );
      throw Error("Auth Error. Abort.");
    }
    this.policyNumber = policyNumberResult?.body?.policyNumber;
  }
});
