import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { defineStore } from "pinia";
import { Router, RouteLocationNormalizedLoaded } from "vue-router";
import InsuranceClient from "@/rest-client/InsuranceClient";
import RequestBuilder from "@/rest-client/RequestBuilder";
import { SumInsured } from "@/interfaces/SumInsured";
import { popupStore } from "./PopupStore";
import { PolicyBenefit, PolicyBenefitPremium } from "@/interfaces/payment/PolicyBenefit";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";
import DateCalculationUtility from "@/utils/DateCalculationUtility";
import { FrequencyDivider } from "@/enums/FrequencyDivider.enum";
import RoundingUtility from "@/utils/RoundingUtility";
import { SubmittedSumInsured } from "@/interfaces/SubmittedSumInsured";

export const insuranceStore = defineStore("insurance", {
  state: () => {
    return {
      policyInfo: {
        policy: {} as InsurancePolicy,
        insuredPerson: {} as any,
        policyRefId: "DefaultPolicyRefId",
        submittedSumInsured: [] as SubmittedSumInsured[],
        sumInsured: {} as SumInsured,
        policyBenefits: [] as PolicyBenefit[],
      },
      versionId: null,
    };
  },
  getters: {
    existingPolicyBenefits: (state) => {
      // Filter policy benefits for Accepted benefits with a valid cover amount
      return state.policyInfo.policyBenefits.filter(
        (policyBenefit) =>
          policyBenefit.benefitStatus === BenefitStatus.Accepted && policyBenefit.coverAmount > 0
      );
    },
  },
  actions: {
    mapFluentValidationErrors(errors: Record<string, Array<string>>) {
      if (!errors) {
        return;
      }

      const mappedErrors = {} as Record<string, any>;
      for (const key in errors) {
        const errorMsgs = errors[key];
        // keys are strings like: Payments[0].DirectDebit.BankAccountNumber
        let levels = key.split(".");

        if (!levels || levels.length <= 0) {
          continue;
        }

        // change all levels to camel case
        levels = levels.map((level) => level.charAt(0).toLowerCase() + level.slice(1));

        let mapPointer = mappedErrors;
        for (const level of levels) {
          if (!mapPointer[level]) {
            mapPointer[level] = {} as Record<string, any>;
          }

          if (levels[levels.length - 1] == level) {
            mapPointer[level] = errorMsgs.join(",");
          }
          mapPointer = mapPointer[level];
        }
      }

      return mappedErrors;
    },
    async parseUrlParameters(router: Router, path: string, policyInfo: string | undefined) {
      // fetch the encoded parameter and generate token -- for production
      // example (before LZW compression and base64 encoding):
      // {
      //   "policyId": "78590c42-57d5-4c06-4266-08db743b818c",
      //   "boosterIdentity": "testIdentity", // don't provide if create a new one
      //   "productType":"0",
      //   "benefitCodeId":"0",
      //   "benefitCode":"Life Cover",
      //   "sumInsured": {
      //     "life":250000,
      //     "trauma":0,
      //     "TPD":0
      //   },
      //   "insuredPerson":{
      //       "firstName": "Richard",
      //       "lastName": "Wang",
      //       "email": "test@test.com",
      //       "mobile": "02041642388",
      //       "gender": "Male",
      //       "smokerStatus": "Nonsmoker",
      //       "dateOfBirth": "1989-01-02",
      //       "address": {
      //           "address1": "add1",
      //           "address2": "add2",
      //           "postcode": "6037",
      //           "city": "wellington",
      //           "country": "NZ",
      //           "isPostal": true,
      //           "isActive": true
      //       }
      //    }
      // }

      const popupBox = popupStore();

      if (!policyInfo) {
        popupBox.showErrorMsg("No valid policy info provided");
        throw Error("Query string (policy info) Error. Abort.");
      }

      const authResult = await InsuranceClient.getToken(policyInfo);

      if (authResult.statusCode != 200) {
        if (authResult.statusCode == 401) {
          popupBox.showSessionExpiredMsg();
          throw Error("Auth Error. Abort.");
        }
        popupBox.showErrorMsg(
          "Failed to fetch the token. Please check your network and try again."
        );
        throw Error("Token Error. Abort.");
      }

      this.policyInfo.policy = authResult.body.policy;

      // Flattens policyOwners[].client into policyOwners[]
      if (this.policyInfo.policy.policyOwners && this.policyInfo.policy.policyOwners.length > 0) {
        this.policyInfo.policy.policyOwners = this.policyInfo.policy.policyOwners.map(
          (po) => po.customer
        );
      }

      this.policyInfo.sumInsured = authResult.body.sumInsured;

      RequestBuilder.setAuthToken(authResult.body.token);

      const policyBenefits = this.policyInfo.policy.policyBenefits;
      // Only create additional policy benefits if there are no currently applications in progress
      if (this.CanCreateNewBenefit(authResult.body.createNewPolicyBenefits, policyBenefits, path)) {
        const createpolicyBenefitsRequest = {
          policyType: this.policyInfo.policy.policyType,
          sumInsured: this.policyInfo.sumInsured,
        };

        const createpolicyBenefitsResponse = await InsuranceClient.createPolicyBenefits(
          createpolicyBenefitsRequest
        );

        if (createpolicyBenefitsResponse.statusCode != 200) {
          popupBox.showErrorMsg("Could not create new benefits");
          throw Error("Policy Benefit Creation Error. Abort.");
        }

        // Update currently used policyBenefits to the new ones created
        this.policyInfo.policy.policyBenefits = createpolicyBenefitsResponse.body;
      }

      const targetApplicant = authResult.body.insuredPerson;
      // This is already filtered when it gets to front end to filter only:
      // Initialised, WaitingForConfirmation, Referred
      // If none exist just get the latest one by InceptionDate
      const targetPolicyRefId =
        this.policyInfo.policy.policyBenefits[0]?.quircPolicy.quircPolicyRefId;

      // Force refresh if the policy is different
      // This will clear all the cache
      if (
        this.policyInfo.insuredPerson?.id &&
        this.policyInfo.insuredPerson.id.length > 0 &&
        this.policyInfo.insuredPerson.id != targetApplicant?.id &&
        this.policyInfo.policyRefId &&
        this.policyInfo.policyRefId.length > 0 &&
        this.policyInfo.policyRefId != targetPolicyRefId
      ) {
        router.go(0);
      }

      this.policyInfo.insuredPerson = targetApplicant;
      this.policyInfo.policyRefId = targetPolicyRefId;

      this.versionId = this.policyInfo.policy.policyBenefits[0]?.quircPolicy.version;

      // Get all policy benefits.
      // Usually we just want to deal with the currently being applied for benefit, but there are some cases where we want all
      const getPolicyResponse = await InsuranceClient.getInsurancePolicy();
      if (getPolicyResponse.statusCode != 200) {
        popupBox.showErrorMsg("Could not get policy");
        throw Error("Get Policy Error. Abort.");
      }
      this.policyInfo.policyBenefits = (getPolicyResponse.body as InsurancePolicy).policyBenefits;

      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.log(this.policyInfo);
      }
    },
    CanCreateNewBenefit(
      createNewPolicyBenefits: boolean,
      policyBenefits: PolicyBenefit[],
      path: string
    ) {
      return (
        createNewPolicyBenefits == true &&
        policyBenefits.every(
          (x: PolicyBenefit) =>
            x.benefitStatus != BenefitStatus.Initialized &&
            x.benefitStatus != BenefitStatus.WaitingForConfirmation
        ) &&
        !path.includes("AfterQuestionnaire")
      );
    },
    showPaymentDetailsCard(
      paymentFrequency: string | undefined,
      premiumAmount: number,
      policyType: string | undefined,
      firstPremiumDate: string | undefined,
      nextPremiumDate: string | undefined
    ): boolean {
      // Refactored from PaymentDetailsCard
      // TODO: conditions for showing payment details card is very confusing and hard to understand
      if (!paymentFrequency || premiumAmount <= 0 || !policyType) {
        return false;
      }
      if (policyType !== "0") {
        // Skip other condition checks
        return true;
      }
      if (
        !nextPremiumDate ||
        nextPremiumDate == "0001-01-01" ||
        (paymentFrequency === "Monthly" &&
          DateCalculationUtility.getDifferenceBetweenTwoDates(
            new Date().toDateString(),
            nextPremiumDate
          ) > 14 &&
          (!firstPremiumDate || firstPremiumDate == "0001-01-01"))
      ) {
        return false;
      }
      return true;
    },
    getPremiumAmountTotalByFrequency(
      policyBenefitPremiums: PolicyBenefitPremium[],
      frequency: string = "Annually"
    ): number {
      // This function takes an array of policyBenefitPremiums and returns a sum of all benefit premiums
      // Each benefit premium amount is calculated from the yearly amount, then rounded to 2dp before being summed
      // NOTE: It is important that we do it in this order, otherwise we will have inconsistent rounding errors:
      // 1. Calculate each premium amount from yearlyPremiumAmount and divide according to frequency
      // 2. Round each divided yearlyPremiumAmount to 2dp
      // 3. Add up all the premium amounts for each benefit together

      // TODO: there is an assumption that the frequency is a valid frequency
      const frequencyDivider =
        FrequencyDivider[frequency.toLocaleUpperCase() as keyof typeof FrequencyDivider];

      return (
        policyBenefitPremiums
          .map((policyBenefitPremium) =>
            RoundingUtility.round(policyBenefitPremium.yearlyPremiumAmount / frequencyDivider)
          )
          // Sum the remaining PolicyBenefitPremiums to get the yearly premium amount
          .reduce((sum, policyBenefitPremium) => sum + policyBenefitPremium, 0)
      );
    },
    flattenedPolicyBenefitPremiums(policyBenefits: PolicyBenefit[]): PolicyBenefitPremium[] {
      // This function flattens all PolicyBenefitPremiums across all PolicyBenefits
      // - will include all benefit statuses (WaitingForConfirmation, Accepted etc)
      return policyBenefits.flatMap(
        (policyBenefit: PolicyBenefit) => policyBenefit.policyBenefitPremiums
      );
    },
  },
});
