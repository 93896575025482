
import { PropType, defineComponent } from 'vue';
import TextInputSmall from '@/components/text-input/TextInputSmall.vue';
import { Address } from "@/interfaces/Address";
import SearchDropdown from "@/components/search-dropdown/SearchDropdown.vue";
import InsuranceClient from '@/rest-client/InsuranceClient';
import SelectInput from "@/components/select-input/SelectInput.vue";

export default defineComponent({
  name: 'AddressDetails',
  components: {
    TextInputSmall,
    SearchDropdown,
    SelectInput
  },
  props: {
    value: {type: Object as PropType<Address | null>, default: null},
    errors: {type: Object, default: () => {}},
    homeAddress: {type: String, default: ''},
    postalAddress: {type: String, default: ''}
  },
  emits: ["update:value"],
  data() {
    return {
      addressDetails: this.value as Address,
      isLoading: false,
      searchResults: [],
      countries: []
    }
  },
  computed: {
    showFieldsForOverseasAddress() {
      return this.homeAddress? this.homeAddress !== 'nativeAddress': this.postalAddress !== 'nativeAddress';
    }
  },
  watch: {
    addressDetails: {
      handler(newValue: Address) {
        if (typeof newValue.address1 === "object") {
          let addressOneLine: any = newValue.address1;
          this.addressDetails.address1 = addressOneLine.addressLine1;
          if (addressOneLine.addressLine2 !== addressOneLine.suburb) {
            this.addressDetails.address2 = addressOneLine.addressLine2;
          }
          this.addressDetails.city = addressOneLine.city;
          this.addressDetails.address3 = addressOneLine.suburb;
          this.addressDetails.postCode = addressOneLine.postCode;
        }
        this.$emit("update:value", newValue);
      },
      deep: true
    },
    homeAddress() {
      this.fetchCountriesList();
      this.addressDetails = this.getNewAddress();
    },
    postalAddress() {
      this.fetchCountriesList();
      this.addressDetails = this.getNewAddress();
    },
  },
  mounted() {
    this.fetchCountriesList();
  },
  methods: {
    async fetchCountriesList() {
      if (this.showFieldsForOverseasAddress && this.countries.length === 0) {
        const response = await InsuranceClient.getCountriesList();
        if (response?.statusCode != 200) {
          return;
        }
        this.countries = response.body.countries.filter((x: any) => x.countryCode !== 'NZ')
          .map((result: any) => ({'text': result.countryName, 'value': result.countryName}));
      }
    },
    readError(field: string) {
      if (!this.errors) {
        return undefined;
      }
      return this.errors[field];
    },
    getNewAddress() {
      return {
        address1: '',
        address2: '',
        address3: '',
        postCode: '',
        city: '',
        state: '',
        country: '',
        isPostal: false
      };
    },
    async searchAndValidateAddress(searchQuery: string) {
      if (!searchQuery) {
        return;
      }
      this.addressDetails.address1 = searchQuery;
      this.isLoading = true;
      const response = await InsuranceClient.searchAndValidateAddress(searchQuery);
      this.isLoading = false;
       if (response?.statusCode != 200) {
        return;
      }
      this.searchResults = response.body.results.map((result: any) => ({'text': result.format.addressOneLine, 'value': result.format}));
    }
  }
})
