
import { PropType, defineComponent } from "vue";
import { SingleChoiceQuestion } from "@/interfaces/singlechoicequestionitem/SingleChoiceQuestion";

export default defineComponent({
  name: "SummaryCard",
  props: {
    title: {type: String, required: true},
    // TODO: Create more generic type for { text/label, value } rather than SingleChoiceQuestion which is quite specific
    items: {type: Array as PropType<Array<SingleChoiceQuestion>>, required: true},
    colour: {type: String, default: "gray"},
    isMobileScreen: {type: Boolean, default: false}
  }
});
