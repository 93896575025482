/// Maps the questionnaire answers into sections based on the heirarchy and config
export default class QuestionnaireAnswerMappingService {
  static async mapQuestionnaireAnswers(questionnaireAnswers: any[], config: any) {
    if (!questionnaireAnswers) {
      return [];
    }

    // Format the questionnaire answers to only include relevant data
    const qAnswersMapped =
      this.mapQuestionnaireAnswerDataToRemoveIrrelevantData(questionnaireAnswers);

    // Format weight, height and monetary answers to include units
    let qAnswersMappedAndFormatted =
      this.mapQuestionnaireAnswerDataWithNumberFormatting(qAnswersMapped);

    // Create a list of all the sections so we can map questions to them
    let qaSections = this.mapQuestionnaireAnswersIntoSections(questionnaireAnswers);

    // Remove sections to be hidden
    if (config.hiddenSections) {
      qaSections = this.filterOutHiddenSections(qaSections, config.hiddenSections);
    }

    // Remove questions to be hidden
    if (config.hiddenQuestions) {
      qAnswersMappedAndFormatted = this.filterOutHiddenQuestions(
        qAnswersMappedAndFormatted,
        config.hiddenQuestions
      );
    }

    // Hide hidden section titles
    if (config.hiddenSectionTitles) {
      qaSections = this.formatHiddenSectionTitles(qaSections, config.hiddenSectionTitles);
    }

    // Remove sections that no longer have any questions and map questionnaire items into sections
    const qaSectionsWithItems = this.mapQuestionnaireItemsIntoSections(
      qaSections,
      qAnswersMappedAndFormatted
    );

    // Combine any sections that have the same title
    const qaSectionsWithItemsConsolidated =
      this.mapQuestionnaireSectionsIntoConsolidatedSections(qaSectionsWithItems);

    // Map all valid sections into the top sections
    let topSectionsWithQuestions = [];
    if (config.topLevelSections) {
      topSectionsWithQuestions = this.mapQuestionnaireSectionsIntoTopLevelSections(
        qaSectionsWithItemsConsolidated,
        config.topLevelSections
      );
    }

    return topSectionsWithQuestions;
  }

  private static mapQuestionnaireAnswerDataToRemoveIrrelevantData(questionnaireAnswers: any[]) {
    return questionnaireAnswers.map((qa: any) => {
      return {
        answerText: this.removeHtmlTags(qa.questionAnswer.answerText),
        questionText: this.removeHtmlTags(qa.questionAnswer.text),
        externalReferenceId: qa.questionAnswer.externalReferenceId,
        sectionExtRefId: qa.questionAnswer.sectionExtRefId,
        sectionTitle: this.removeHtmlTags(qa.questionAnswer.sectionTitle),
        parentSectionSequence: qa.parentSectionSequence,
        parentId: qa.parentId,
        parentExternalReferenceId: qa.parentExternalReferenceId,
        parentSectionTitle: this.removeHtmlTags(qa.parentSectionTitle),
      };
    });
  }

  private static mapQuestionnaireAnswerDataWithNumberFormatting(questionnaireAnswers: any[]) {
    // TODO: There does not seem to be any indication of kg/lb and metres/feet
    const weightExtRefId = "Weight_kg";
    const heightExtRefId = "Height";
    const questionsWithDollarSigns = [
      "Annual_income",
      "Annual_income_other_occupation",
      "Existing_life_cover",
      "Existing_TPD_cover",
      "Existing_IP_cover",
      "Existing_CI_cover",
    ];

    return questionnaireAnswers.map((qa: any) => {
      if (qa.externalReferenceId === weightExtRefId) {
        qa.answerText = `${qa.answerText} kilograms`;
      }

      if (qa.externalReferenceId === heightExtRefId) {
        qa.answerText = `${qa.answerText} metres`;
      }

      if (questionsWithDollarSigns.includes(qa.externalReferenceId)) {
        qa.answerText = `$${parseFloat(qa.answerText.toString()).toLocaleString()}`;
      }

      return qa;
    });
  }

  private static mapQuestionnaireAnswersIntoSections(questionnaireAnswers: any[]) {
    return questionnaireAnswers
      .map((qa: any) => {
        return {
          section: qa.questionAnswer.sectionExtRefId,
          sectionTitle: qa.questionAnswer.sectionTitle,
          parentSectionSequence: qa.questionAnswer.parentSectionSequence,
          parentId: qa.parentId,
          parentExternalReferenceId: qa.parentExternalReferenceId,
          parentTitle: qa.parentSectionTitle,
        };
      })
      .reduce(
        (a: any, b: any) => (a.map((s: any) => s.section).includes(b.section) ? a : [...a, b]),
        []
      );
  }

  private static filterOutHiddenSections(qaSections: any[], hiddenSectionsConfig: any) {
    return qaSections
      .filter(
        // Filter out any sections found in the hidden section list
        (section: { section: string; sectionTitle: string }) =>
          // if config.hiddenSections.names exists, then apply filter, otherwise return this item
          hiddenSectionsConfig.names ? !hiddenSectionsConfig.names.includes(section.section) : true
      )
      .filter(
        // Filter out any sections that are prefixed with any in the hidden section prefix list
        (section: { section: string; sectionTitle: string }) =>
          hiddenSectionsConfig.prefixes
            ? hiddenSectionsConfig.prefixes.every(
                (prefix: string) => !section.section.startsWith(prefix)
              )
            : true
      );
  }

  private static filterOutHiddenQuestions(questionnaireAnswers: any[], hiddenQuestionsConfig: any) {
    // Filter out any questions that are prefixed with any in the hidden question prefix list
    return questionnaireAnswers.filter((qa: any) =>
      hiddenQuestionsConfig.prefixes
        ? !hiddenQuestionsConfig.prefixes.every((prefix: string) =>
            qa.externalReferenceId.startsWith(prefix)
          )
        : true
    );
  }

  private static formatHiddenSectionTitles(qaSections: any[], hiddenSectionTitlesConfig: any) {
    // Replaces any section titles to be hidden with an empty string
    return qaSections.map((section: any) => {
      if (!hiddenSectionTitlesConfig.prefixes) {
        // if the config does not contain prefixes, return the item, do nothing and don't error out
        return section;
      }

      const newSectionTitle = hiddenSectionTitlesConfig.prefixes.reduce(
        (a: string, b: string) => (a.startsWith(b) ? "" : a),
        section.section
      );
      return { ...section, sectionTitle: newSectionTitle === "" ? "" : section.sectionTitle };
    });
  }

  private static mapQuestionnaireItemsIntoSections(qaSections: any[], questionnaireAnswers: any[]) {
    // TODO: combine any sections with the same parent section and without a section title
    return (
      qaSections
        .map((section: any) => {
          return {
            title: section.parentTitle ?? section.sectionTitle,
            sectionTitle: section.section,
            parentSectionSequence: section.parentSectionSequence,
            parentId: section.parentId,
            parentExternalReferenceId: section.parentExternalReferenceId,
            parentTitle: section.parentTitle,
            questionnaireAnswers: questionnaireAnswers.filter(
              (qa: any) => qa.sectionExtRefId === section.section
            ),
          };
        })
        // Filter out any empty sections
        .filter((section: any) => section.questionnaireAnswers.length > 0)
    );
  }

  private static mapQuestionnaireSectionsIntoConsolidatedSections(qaSections: any[]) {
    return qaSections.reduce((processedSections: any[], currentSection: any) => {
      // Get a list of previous section titles
      const processedSectionTitles = processedSections.map((section) => section.title);

      if (processedSectionTitles.includes(currentSection.title)) {
        // Get the matching previous section
        const matchingSection = processedSections.find(
          (section) => section.title === currentSection.title
        );
        if (!matchingSection) {
          // Null checking
          return [...processedSections, currentSection];
        }

        matchingSection.questionnaireAnswers = [
          ...matchingSection.questionnaireAnswers,
          ...currentSection.questionnaireAnswers,
        ];
        return processedSections;
      }
      return [...processedSections, currentSection];
    }, []);
  }

  private static mapQuestionnaireSectionsIntoTopLevelSections(
    qaSectionsWithItems: any[],
    topLevelSectionsConfig: any
  ) {
    const topLevelSections = topLevelSectionsConfig.filter((section: any) => !section.static);

    return topLevelSections.map((section: any) => {
      return {
        title: section.name,
        questionnaireAnswerSubSections: qaSectionsWithItems.filter(
          (subsection: any) =>
            // Sometimes the sectionExtRefId is null and we must check the parentExtRefId
            subsection.sectionTitle.startsWith(section.prefix) ||
            subsection.parentExternalReferenceId?.startsWith(section.prefix)
        ),
      };
    });
  }

  private static removeHtmlTags(text: string): string {
    if (!text) {
      return text;
    }
    return text.replace(/(<([^>]+)>)/gi, "");
  }
}
