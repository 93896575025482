
import { defineComponent } from "vue";
import { popupStore } from "@/stores/PopupStore";
import { ProductName } from "@/enums/ProductName.enum";

export default defineComponent({
  name: "CoverAmountCard",
  props: {
    title: {type: String, default: ""},
    value: {type: Number, required: true},
    content: {type: Array, required: true},
    monthlyCost: {type: Number, required: true},
    minCover: {type: Number, required: true},
    maxCover: {type: Number, required: true},
    showRemove: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    validationError: {type: Boolean, default: false}
  },
  emits: ["update-values"],
  data() {
    return {
      internalValue: this.value,
      iconRoute:  this.getIconRoute(this.title),
      showPopup: false
    };
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    updateValue() {
      // When value is -1, it is removed from quote, so don't check cover amount or emit again
      if (this.internalValue == -1) {
        return;
      }

      if (this.internalValue > this.maxCover) {
        this.internalValue = this.maxCover;
      }

      if (isNaN(this.internalValue) || this.internalValue < this.minCover) {
        this.internalValue = this.minCover;
      }

      this.$emit("update-values", Number(this.internalValue));
    },
    showRemoveConfirmation() {
      const popupBox = popupStore();
      popupBox.popupState = {
        title: "Remove from quote",
        content: `Are you sure you want to remove ${this.title} from your quote?`,
        secondaryText: "No",
        okay: "Yes",
        showPopupBox: true,
        primaryActionBtn: this.removeBenefit
      };
    },
    removeBenefit() {
      this.$emit("update-values", -1);
    },
    getIconRoute(title: string) {
      return (title == ProductName.CLI.toString() || title == ProductName.SSLI.toString() ) ? new URL('/src/assets/umbrella-green.svg', import.meta.url)
       : title == ProductName.TPD.toString() ? new URL('/src/assets/wheelchair-green.svg', import.meta.url)
       : new URL('/src/assets/heartbeat-green.svg', import.meta.url)
    },
    validateNumber(event:KeyboardEvent) {
      if (!/[\d.]/.test(event.key)) return event.preventDefault();
    }
  },
});
