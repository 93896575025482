import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "col-12 item-label mb-2 ps-0" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["disabled", "onClick"]
const _hoisted_5 = ["id", "value", "disabled"]
const _hoisted_6 = ["for"]
const _hoisted_7 = {
  key: 2,
  class: "row mt-1 item-validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "multi-choice-item"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({'row': !_ctx.isSmallScreen})
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["pt-1 mb-2 item-label-alt", {'row': !_ctx.isSmallScreen}]),
            innerHTML: _ctx.description
          }, null, 10, _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mb-0", {'row': !_ctx.isSmallScreen}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.value,
          class: "text-start col-lg-auto col-md-auto col-sm-auto col-xs-12 px-1 mb-2 ps-0"
        }, [
          (!option.isOverrideAnswer)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass([{
            active: _ctx.isSelected(option),
            'validation-error': _ctx.validationError,
          }, "btn btn-option me-1 item-body ins-form-control"]),
                type: "button",
                disabled: _ctx.noneOfTheAboveChecked || _ctx.isDisabled,
                "data-bs-toggle": "button",
                onClick: ($event: any) => (_ctx.clickEventHandler(option))
              }, _toDisplayString(option.text), 11, _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 2),
    (_ctx.overrides && _ctx.overrides.length > 0)
      ? (_openBlock(), _createElementBlock("hr", {
          key: 0,
          class: _normalizeClass(["hr mt-2", {'margin-row': !_ctx.isSmallScreen, 'me-2': _ctx.isSmallScreen}])
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.overrides && _ctx.overrides.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({'row': !_ctx.isSmallScreen, 'd-flex flex-wrap-none': _ctx.isSmallScreen && _ctx.overrides.length === 2})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.overrides, (overrideOption) => {
            return (_openBlock(), _createElementBlock("div", {
              key: overrideOption.value,
              class: _normalizeClass(["col-lg-auto col-md-auto col-sm-auto col-xs-12 ps-0", {'flex-fill': _ctx.isSmallScreen && _ctx.overrides.length === 2}])
            }, [
              _withDirectives(_createElementVNode("input", {
                id: `no-option-${_ctx.id}`,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.noneOfTheAbove) = $event)),
                class: _normalizeClass(["form-check-input check-item me-2 mb-2", {'validation-error': _ctx.validationError}]),
                type: "checkbox",
                value: overrideOption.value,
                disabled: 
            _ctx.isDisabled ||
              (_ctx.noneOfTheAbove[0] != overrideOption.value && _ctx.noneOfTheAboveChecked)
          
              }, null, 10, _hoisted_5), [
                [_vModelCheckbox, _ctx.noneOfTheAbove]
              ]),
              _createElementVNode("label", {
                class: "item-body",
                for: `no-option-${_ctx.id}`
              }, _toDisplayString(overrideOption.text ?? "None of the above"), 9, _hoisted_6)
            ], 2))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.validationError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.validationError), 1))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}