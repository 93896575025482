
import { PropType, defineComponent } from "vue";
import { AnswerOption } from "@/interfaces/AnswerOption";

export default defineComponent({
  name: "MultipleChoiceQuestionWithNoChoice",
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    id: {type: String, default: ""},
    options: {type: Array as PropType<AnswerOption[]>, required: true},
    value: {type: Array as PropType<string[]> , default: () => []},
    overrides: {type: Array as PropType<AnswerOption[]>, required: true},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    isSmallScreen: {type: Boolean, default: false}
  },
  emits: ["update:value"],
  data() {
    return {
      noneOfTheAbove: [] as Array<string>,
      selectedItems: this.value,
    };
  },
  computed: {
    noneOfTheAboveChecked() {
      return this.noneOfTheAbove && this.noneOfTheAbove.length > 0;
    },
  },
  watch: {
    noneOfTheAbove(newVal) {
      this.selectedItems = newVal.length > 0 ? newVal : null;
      this.$emit("update:value", this.selectedItems);
    },
  },
  mounted() {
    if (this.overrides && this.selectedItems) {
      let overrideValues = this.overrides.map((x) => x.value);

      let newNoneOfTheAbove = this.selectedItems.filter((x: string) =>
        overrideValues.includes(x),
      );
      if (newNoneOfTheAbove.length > 0) {
        this.noneOfTheAbove = newNoneOfTheAbove;
      }
    }
  },
  methods: {
    clickEventHandler(option: AnswerOption) {
      const values = this.selectedItems ?? [];
      // toggling selected item
      this.selectedItems = values.includes(option.value)
        ? values.filter((x) => x != option.value && x != null)
        : [...values, option.value];
      let val =
        this.selectedItems.length < 1 && !this.noneOfTheAboveChecked
          ? null
          : this.selectedItems;
      this.$emit("update:value", val);
    },
    isSelected(option: AnswerOption) {
      const isSelected =
        (this.selectedItems && this.selectedItems.includes(option.value)) ??
        false;
      return isSelected;
    },
  },
});
