import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c7adb47"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-body p-4"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "form-check mt-2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  class: "item-content",
  for: "agreeCheck"
}
const _hoisted_6 = {
  key: 0,
  class: "mt-1 item-validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'row me-0': !_ctx.isSmallScreen})
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["agreement-card card bg-light-blue", {'px-0 rounded-0 status-banner-small-screen': _ctx.isSmallScreen}])
    }, [
      (!_ctx.status)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["item-content", {'me-1': _ctx.isSmallScreen}]),
              innerHTML: _ctx.content
            }, null, 10, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                id: "agreeCheck",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
                type: "checkbox",
                class: _normalizeClass(["form-check-input check-item me-2", {'validation-error': _ctx.validationError}]),
                disabled: _ctx.isDisabled
              }, null, 10, _hoisted_4), [
                [_vModelCheckbox, _ctx.internalValue]
              ]),
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.option.text), 1)
            ]),
            (_ctx.validationError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.validationError), 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["card-body", {'px-4 py-2': _ctx.isSmallScreen}])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["item-content", {'item-label-alt': _ctx.isSmallScreen}])
            }, _toDisplayString(_ctx.status), 3)
          ], 2))
    ], 2)
  ], 2))
}