
import InsuranceQuestionnaire from "@/components/pages/questionnaire-page/InsuranceQuestionnaire.js";
import { Answer } from "@/interfaces/Answer";
import { Question } from "@/interfaces/Question";
import { Condition } from "@/interfaces/Condition";
import { PropType, defineComponent } from "vue";
export default defineComponent({

  name: "SummaryQuestion",
  props: {
    questions: {type: Array as PropType<Question[]>, required:true},
  },
  data() {
    return {
      unhydratedQuestions: this.questions,
      hydratedQuestions: [] as Array<Question> | null,
    };
  },
  async created() {
    this.hydratedQuestions = await this.hydrateAnswers(
      this.unhydratedQuestions,
    );
  },
  methods: {
    async hydrateAnswers(questions: Question[]) {
      let questionsWithDollarSigns = ["Annual_income", "Annual_income_other_occupation", "Existing_life_cover", "Existing_TPD_cover", "Existing_IP_cover","Existing_CI_cover"];
      let mappedQuestions = await Promise.all(
        questions.map(async (question: Question) => {
          if (!question.value) {
            question.textValue = "Question not answered";
          } else if (
            question.answer[0].answerType == "BasicAnswerProvider" &&
            Number.isInteger(parseInt(question.value.toString()))
          ) {
            question.textValue = this.getAnswerString(question);
          } else if (
            question.answer[0].answerType == "SelectionAnswerProvider" ||
            question.answer[0].answerType == "MultipleSelectionAnswerProvider"
          ) {
            question.textValue = await this.mapCondition(question);
          }
          if (questionsWithDollarSigns.includes(question.externalReferenceId)) {
            const val = parseFloat(question.value.toString());
            question.textValue = '$' + val.toLocaleString();
          }
          if (question.externalReferenceId === 'Weight_kg') {
            question.textValue = question.value + ' ' + (question.title?.includes('kilograms') ? 'kilograms': 'pounds');
          }
          if (question.externalReferenceId === 'Height') {
            question.textValue = question.value + ' ' + (question.title?.includes('metres') ? 'metres': 'feet');
          }

          question.isAnswerLoaded = true;
          return question;
        }),
      );
      return mappedQuestions;
    },
    getAnswerString(question: Question) {
      if (!Array.isArray(question.value)) {
        question.value = [question.value];
      }

      let answerValues = question.value.map((value: string) => {
        return question.answer.find((x: Answer) => x.value == value)?.text;
      });

     return answerValues.join(", ");
    },
    async mapCondition(question: Question) {
      let conditions = await InsuranceQuestionnaire.getConditions(
        question.externalReferenceId,
      );
      if (!Array.isArray(question.value)) {
        question.value = [question.value];
      }

      let answerValues = question.value.map((value) => {
        return conditions.find((x: Condition) => x.value == value).text;
      });

      return answerValues.join(", ");
    },
  },
});
