import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoosterNav = _resolveComponent("BoosterNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PopupBox = _resolveComponent("PopupBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BoosterNav, { "screen-width": _ctx.screenWidth }, null, 8, ["screen-width"]),
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.path,
        "screen-width": _ctx.screenWidth
      }, null, 8, ["screen-width"]))
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_PopupBox, {
        showPopupBox: _ctx.popupState.showPopupBox,
        "onUpdate:showPopupBox": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.popupState.showPopupBox) = $event)),
        content: _ctx.popupState.content,
        title: _ctx.popupState.title,
        okay: _ctx.popupState.okay,
        "secondary-text": _ctx.popupState.secondaryText,
        "screen-width": _ctx.screenWidth,
        onPrimaryBtnAction: _ctx.popupState.primaryActionBtn
      }, null, 8, ["showPopupBox", "content", "title", "okay", "secondary-text", "screen-width", "onPrimaryBtnAction"])
    ])
  ], 64))
}