import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b75ea4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-picker" }
const _hoisted_2 = { class: "item-label" }
const _hoisted_3 = { class: "item-label-alt" }
const _hoisted_4 = { class: "position-relative" }
const _hoisted_5 = {
  key: 0,
  class: "mt-1 item-validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.description), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_VueDatePicker, {
        modelValue: _ctx.selectedValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
        format: "dd-MM-yyyy",
        placeholder: "dd-mm-yyyy",
        "hide-input-icon": "",
        "auto-apply": "",
        "no-today": "",
        "prevent-min-max-navigation": "",
        "text-input": _ctx.allowTextInput,
        "text-input-options": _ctx.dpTextInputOptions,
        "enable-time-picker": false,
        class: _normalizeClass(["ins-date-picker", {'validation-error': _ctx.validationError}]),
        disabled: _ctx.isDisabled,
        "min-date": _ctx.minValue,
        "max-date": _ctx.maxValue,
        onOpen: _ctx.handleDatePickerOpen,
        onClosed: _ctx.handleDatePickerClose,
        onCleared: _ctx.handleDatePickerInputCleared
      }, null, 8, ["modelValue", "text-input", "text-input-options", "class", "disabled", "min-date", "max-date", "onOpen", "onClosed", "onCleared"]),
      _withDirectives(_createElementVNode("i", {
        class: _normalizeClass(["bi bi-chevron-down icon", { 'icon-mobile': _ctx.isMobileScreen, 'icon-left-width': !_ctx.isMobileScreen }])
      }, null, 2), [
        [_vShow, _ctx.showChevron]
      ])
    ]),
    (_ctx.validationError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.validationError), 1))
      : _createCommentVNode("", true)
  ]))
}