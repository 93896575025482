
import { defineComponent } from "vue";

export default defineComponent({
  name: "CollapsibleCard",
  props: {
    title: {type: String, required: true}
  },
  data() {
    return {
      isCollapsed: true
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      return this.isCollapsed;
    }
  }
});
