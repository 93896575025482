import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-input-small col-lg-auto col-md-auto col-sm-auto col-xs-12" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["item-label", _ctx.inputClass])
    }, _toDisplayString(_ctx.title), 3),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["pt-1 mb-2 item-label-alt", _ctx.inputClass]),
          innerHTML: _ctx.description
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enteredValue) = $event)),
      class: _normalizeClass(["item-body ins-form-control ps-2", [{ 'validation-error': _ctx.validationError }, _ctx.inputClass]]),
      placeholder: _ctx.placeholder,
      disabled: _ctx.isDisabled
    }, null, 10, _hoisted_3), [
      [_vModelText, _ctx.enteredValue]
    ]),
    (_ctx.validationError)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["mt-1 item-validation-error", _ctx.inputClass])
        }, _toDisplayString(_ctx.validationError), 3))
      : _createCommentVNode("", true)
  ]))
}