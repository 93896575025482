<template>
  <div v-if="items.length > 0">
    <div class="row w-100 ins-select-form-style">
      <div
        class="dropdown-trigger item-body fw-bold text-start d-flex align-items-center p-0 rounded-0 border-0 position-absolute d-flex justify-content-between align-items-center"
        type="button"
        href="#"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click="toggleDropdown()">
        <div class="ps-4">{{ selectedItem.text }}</div>
        <i
          class="bi icon me-3"
          :class="isDropdownExpanded ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
      </div>
      <ul class="dropdown-menu rounded-0 border-0">
        <li
          v-for="(option, i) in items"
          :key="i"
          class="dropdown-item item-content d-flex align-items-center pb-4 ps-0"
          :value="option"
          :class="{ 'fw-bold bg-white': option.id === selectedItem.id, 'item-placeholder-gray': !isClickable(option, items[i - 1] ?? null)}"
          :disabled="!isClickable(option, items[i - 1] ?? null)"
          @click="setActive(option, i)">
          <div class="icon-container flex-shrink-0 ms-4">
            <span v-if="option.isChecked"><img src="@/assets/step-tick-blue.svg" /></span>
          </div>
          <span>{{ option.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "HorizontalNavSubList",
  props: {
    items: {type: Array, required: true}
  },
  emits: ['dropdownToggled'],
  data() {
    const selectedOption = this.items.filter(item => this.$router.currentRoute.value.path.includes(item.id));
    return {
      selectedItem: selectedOption[0],
      isDropdownExpanded: false
    }
  },
  watch: {
    'selectedItem'() {
      this.$router.push(this.selectedItem.link);
    },
  },
  methods: {
    isClickable(item, prevItem) {
      if (prevItem == null || item.isChecked == true || prevItem.isChecked) {
        return true;
      }
      return false;
    },
    setActive(item, index) {
      if(this.isClickable(item, this.items[index-1] ?? null)) {
        this.selectedItem = item;
      }
    },
    toggleDropdown() {
      this.$emit('dropdownToggled');
      this.isDropdownExpanded = !this.isDropdownExpanded;
    }
  }
};
</script>

Add "scoped" attribute to limit SCSS to this component only
<style lang="scss" scoped>
@import "./HorizontalInsuranceStepNavigator.scss";
</style>
