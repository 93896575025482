
import { defineComponent } from "vue";
import SummarySection from "@/components/summary-overview/SummarySection.vue";
import { insuranceStore } from "@/stores/InsuranceStore";
import { mapState } from "pinia";

export default defineComponent({
  name: "SummaryOverview",
  components: {
    SummarySection,
  },
  props: {
    summary: { type: Object, required: true },
    isSmallScreen: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
  },
});
