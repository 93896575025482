import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { navStore } from "@/stores/NavStore";
import RequestBuilder from "@/rest-client/RequestBuilder";

const pinia = createPinia();

createApp(App).use(router).use(pinia).mount("#app");

// Clear nav menu cache before each router push
const nStore = navStore();
router.beforeResolve(() => {
  nStore.navItems = [];
});

// Set up the axios interceptors
RequestBuilder.interceptorSetup();
