import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76d6261f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-input" }
const _hoisted_2 = { class: "row text-start item-label" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: "row item-label-alt mb-2",
      innerHTML: _ctx.description
    }, null, 8, _hoisted_3),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        class: _normalizeClass(["item-content ins-form-control col-12", [{ 'validation-error': _ctx.validationError }, _ctx.inputClass]]),
        placeholder: _ctx.placeholder,
        rows: "3",
        maxlength: "255",
        disabled: _ctx.isDisabled
      }, null, 10, _hoisted_5), [
        [_vModelText, _ctx.inputValue]
      ])
    ]),
    (_ctx.validationError)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mt-1 item-validation-error", _ctx.inputClass])
        }, _toDisplayString(_ctx.validationError), 3))
      : _createCommentVNode("", true)
  ]))
}