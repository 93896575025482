
import { defineComponent } from "vue";
import SummaryQuestion from "@/components/summary-overview/SummaryQuestion.vue";
export default defineComponent({
  name: "SummarySection",
  components: {
    SummaryQuestion,
  },
  props: {
    subSections: {type: Object, required: true},
    layer: {type: Number, default: -1}
  },
  data() {
    return {
      currentLayer: this.layer == null ? 0 : this.layer + 1,
    };
  },
});
