
import { mapActions } from 'pinia';
import { navStore } from '@/stores/NavStore';
import { PropType, defineComponent } from "vue";
import { VerticalNavItem } from "@/interfaces/verticalInsuranceNavSubList/VerticalNavItem";

export default defineComponent({
  name: "VerticalNavSubList",
  props: {
    items: {type: Array as PropType<VerticalNavItem[]>, required:true},
    activeItem: {type: String, required: true}
  },
  methods: {
    ...mapActions(navStore, ['isItemActive']),
    isActive(activeItem: string, item: VerticalNavItem) {
          return this.isItemActive(activeItem, item);
    },
    isClickable(item: VerticalNavItem, prevItem: VerticalNavItem) {
      if (prevItem == null || item.isChecked == true || prevItem.isChecked) {
          return true;
      }
      return false;
    },
  },
});
