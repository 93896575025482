import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "single-choice-item" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["disabled", "onClick"]
const _hoisted_4 = {
  key: 0,
  class: "row mt-1 item-validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["item-label", {'row': !_ctx.isSmallScreen}])
    }, _toDisplayString(_ctx.title), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["pt-1 mb-2 item-label-alt", {'row': !_ctx.isSmallScreen}]),
      innerHTML: _ctx.description
    }, null, 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass({'row': !_ctx.isSmallScreen})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.value,
          class: _normalizeClass(["col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0 d-flex align-items-center mb-2", {'col': _ctx.options.length === 2, 'me-2': !_ctx.isSmallScreen && option !== _ctx.options[_ctx.options.length-1]}])
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(["btn btn-option ins-form-control item-body", {
            active: _ctx.selectedValue == option.value,
            'validation-error': _ctx.validationError,
          }]),
            disabled: _ctx.isDisabled,
            onClick: ($event: any) => (_ctx.clickEventHandler(option))
          }, _toDisplayString(option.text), 11, _hoisted_3)
        ], 2))
      }), 128))
    ], 2),
    (_ctx.validationError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.validationError), 1))
      : _createCommentVNode("", true)
  ]))
}