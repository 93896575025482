import axios, { InternalAxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { popupStore } from "@/stores/PopupStore";

export default class RequestBuilder {
  static token = "";

  static insuranceApi = axios.create({
    baseURL: process.env.VUE_APP_INS_XAPI_URL,
    // have full status in the response body instead of throw exceptions
    validateStatus: () => true,
  });

  static interceptorSetup() {
    RequestBuilder.insuranceApi.interceptors.request.clear();
    RequestBuilder.insuranceApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      if (RequestBuilder.token && RequestBuilder.token.length > 0) {
        config.headers["X-Policy-Token"] = RequestBuilder.token;
      }
      return config;
    }, null);

    RequestBuilder.insuranceApi.interceptors.response.clear();
    RequestBuilder.insuranceApi.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.status == 401) {
          const popupBox = popupStore();
          popupBox.showSessionExpiredMsg();
          throw Error("Auth Error. Abort.");
        }
        return response;
      },
      (error: AxiosError | Error) => {
        const popupBox = popupStore();
        popupBox.showErrorMsg("Network error. Please refresh and try again.");
        Promise.reject(error);
      }
    );
  }

  static async makePutRequest(url: string, requestBody: any) {
    const response = await RequestBuilder.insuranceApi.put(url, requestBody);
    const responseBody = response.status == 200 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makePostRequest(url: string, requestBody: any = null, config?: any) {
    const response = await RequestBuilder.insuranceApi.post(url, requestBody, config);
    const responseBody =
      response.status == 200 || response.status == 400 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makePatchRequest(url: string, requestBody: any) {
    const response = await RequestBuilder.insuranceApi.patch(url, requestBody);
    const responseBody =
      response.status == 200 || response.status == 400 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makeGetRequest(url: string, params: Record<string, string> | null = null) {
    if (params) {
      const searchParams = new URLSearchParams(params);
      url = `${url}?${searchParams.toString()}`;
    }

    const response = await RequestBuilder.insuranceApi.get(url);
    const responseBody = response.status == 200 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static async makeDeleteRequest(url: string, requestBody: any = null) {
    const response = await RequestBuilder.insuranceApi.delete(url, requestBody);
    const responseBody = response.status == 200 ? await response.data : null;

    return {
      statusCode: response.status,
      body: responseBody,
    };
  }

  static setAuthToken(token: string) {
    RequestBuilder.token = token;
  }
}
