import { defineStore } from "pinia";

export const popupStore = defineStore("popup", {
  state: () => {
    return {
      popupState: {
        title: "",
        content: "",
        secondaryText: "",
        okay: "Okay",
        showPopupBox: false,
        primaryActionBtn: {},
      },
    };
  },
  actions: {
    showMsg(msg: string) {
      this.popupState.content = msg;
      this.popupState.showPopupBox = true;
    },
    showErrorMsg(msg: string) {
      this.popupState.title = "Error";
      this.popupState.secondaryText = "";
      this.popupState.okay = "Okay";
      this.popupState.primaryActionBtn = {};
      this.showMsg(msg);
    },
    showWarningMsg(msg: string) {
      this.popupState.title = "Warning";
      this.popupState.secondaryText = "";
      this.popupState.okay = "Okay";
      this.popupState.primaryActionBtn = {};
      this.showMsg(msg);
    },
    showInfoMsg(msg: string) {
      this.popupState.title = "Information";
      this.popupState.secondaryText = "";
      this.popupState.okay = "Okay";
      this.popupState.primaryActionBtn = {};
      this.showMsg(msg);
    },
    showSessionExpiredMsg() {
      this.popupState.title = "Session expired";
      this.popupState.secondaryText = "";
      this.popupState.okay = "Got it";
      this.popupState.primaryActionBtn = {};
      this.showMsg("Sorry, your session has expired. Please log back in and resume the application.");
    },
  },
});
