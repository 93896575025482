/// Map from sum insured to benefit
export default class SumInsuredMap {
  /// Round the number to 2 decimal places
  static mapToSumInsured(benefitCode: string) {
    const map: Record<string, string> = {
      "Life Cover": "life",
      "Disability Cover": "tpd",
      "Critical Illness Cover": "trauma",
    };

    return map[benefitCode];
  }
}
