import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f9058de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-2" }
const _hoisted_2 = {
  key: 0,
  class: "item-tertiary-title smaller-text pb-2"
}
const _hoisted_3 = {
  key: 0,
  class: "item-tertiary-title smaller-text"
}
const _hoisted_4 = { class: "item-body" }
const _hoisted_5 = {
  key: 1,
  class: "sub-section-divider mt-2 pb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollapsibleCard = _resolveComponent("CollapsibleCard")!

  return (_openBlock(), _createBlock(_component_CollapsibleCard, {
    title: _ctx.title,
    class: _normalizeClass({'w-50': !_ctx.isMobileScreen})
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subSections, (subSection, subSectionIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: subSectionIndex,
            class: "d-grid smaller-text"
          }, [
            (subSection.title !== '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(subSection.title), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subSection.questionnaireAnswers, (questionAnswer, qaIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: qaIndex,
                class: "pb-2"
              }, [
                (questionAnswer.questionText !== '')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(questionAnswer.questionText), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, _toDisplayString(questionAnswer.answerText), 1)
              ]))
            }), 128)),
            (_ctx.showSectionDivider(subSection.title, _ctx.subSections.length, subSectionIndex))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "class"]))
}