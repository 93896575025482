
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import { popupStore } from "@/stores/PopupStore";
import CancelApplicationResult from "@/components/result-information/CancelApplicationResult.vue";

export default defineComponent({
  name: "CancelPaymentTab",
  components: {
    CancelApplicationResult
  },
  props: {
    screenWidth: {type: String, required: true},
    isMobileScreen: {type: Boolean, default: false}
  },
  data() {
    return {
      showCancelledApplicationResult: false
    }
  },
  computed: {
    ...mapState(confirmationStore, ["policyInfo"])
  },
  methods: {
    ...mapActions(confirmationStore, ['getNextPage', 'applicationCancel']),
    async goToNextPage() {
      const nextPath = this.getNextPage(this.$route.fullPath.replace("PaymentCancelled", "DDConfirmation"));
      if(nextPath) {
        this.$router.push(nextPath);
      }
    },
    cancelApplicationPopup() {
      const popupBox = popupStore();
      popupBox.popupState = {
        title: "Cancel application",
        content: "Are you sure you want to cancel your application?",
        secondaryText: "No",
        okay: "Yes",
        showPopupBox: true,
        primaryActionBtn: this.cancelApplication
      };
    },
    async cancelApplication() {
      const response = await this.applicationCancel();
      if (!response) {
        const popupBox = popupStore();
        popupBox.showErrorMsg("Error occurred while cancelling application.");
        return;
      }

      this.showCancelledApplicationResult = true;
    },
  }
});
