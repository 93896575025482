import InsuranceClient from "@/rest-client/InsuranceClient";
import QuestionnaireDataGenerator from "./QuestionnaireDataGenerator";

export default class QuestionnaireDataAccelerator {
  static profileCache: any;

  static async getProfileToCache() {
    if (!this.profileCache) {
      const response = await InsuranceClient.getQuestionnaireProfile();
      if (response.statusCode != 200) {
        if (process.env.VUE_APP_LOG_LEVEL > 0) {
          console.warn("Failed to fetch profile data. This may cause a delay when loading.");
        }
        return;
      }

      this.profileCache = response.body;
    }

    QuestionnaireDataAccelerator.setSectionCache(this.profileCache.sections);
  }

  static setSectionCache(sections: any, parentId = null, parentExtRefId = null) {
    if (sections) {
      sections.forEach((section: any) => {
        section.parentId = parentId;
        section.parentExternalReferenceId = parentExtRefId;

        // Create a copy without sub sections info
        const sectionCopy = Object.assign({}, section);
        delete sectionCopy.sections;

        QuestionnaireDataGenerator.setSectionDataCache(section.externalReferenceId, sectionCopy);

        if (section.questions) {
          QuestionnaireDataGenerator.setQuestionDataCache(
            section.externalReferenceId,
            section.questions
          );
        }

        if (section.sections) {
          QuestionnaireDataAccelerator.setSectionCache(
            section.sections,
            section.id,
            section.externalReferenceId
          );
        }
      });
    }
  }
}
