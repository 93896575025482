
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "NumberInput",
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    icon: {type: String, default: ""},
    value: {type: [String, Number, null] as PropType<String | number | null>, default: -1},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    inputClass: {type: String, default: ""},
    inputPadding: {type: Boolean, default: false},
  },
  emits: ["update:value"],
  data() {
    return {
      enteredValue: this.value,
    };
  },
  watch: {
    enteredValue() {
      this.$emit("update:value", this.enteredValue?.toString() ?? 0);
    },
  },
  mounted() {
    if (this.value == -1) {
      this.enteredValue = null;
    }
  },
  methods: {
    validateNumber(event:KeyboardEvent) {
      this.enteredValue = this.enteredValue?.toString()?.replace(/,/g, '') ?? null;
      if (!/[\d.]/.test(event.key)) return event.preventDefault();
    }
  },
});
