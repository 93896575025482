
import { defineComponent } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  components: {
    VueDatePicker,
  },
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    value: {type: String, default: null},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    minValue: {type: Date, default: new Date("0001-01-01T00:00:00Z")},
    maxValue: {type: Date, default: new Date("9999-11-31T00:00:00Z")},
    isMobileScreen: {type: Boolean, default: false},
    allowTextInput: {type: Boolean, default: true}
  },
  emits: ["update:value", "update:clientValidationError"],
  data() {
    return {
      selectedValue: this.value as string | null,
      tzoffset: new Date().getTimezoneOffset() * 60000,
      clientValidationError: this.validate(this.value),
      showChevron: true, // Show dropdown chevron when dropdown is closed and there is no text inputted
      dpTextInputOptions: { format: [
        "dd-MM-yyyy",
        "ddMMyyyy",
        "dd/MM/yyyy",
        "ddMMyyyy",
        "dd MMM yyyy",
        "dd MMMM yyyy",
        "ddMMMMyyyy"]}
    };
  },
  watch: {
    selectedValue(newValue) {
      let updatedValue = null;
      if (newValue) {
        updatedValue = this.formatDate(newValue);
        // Hide chevron so it doesn't overlap with clear (x) button
        this.showChevron = false;
      }
      this.$emit("update:value", updatedValue);
      this.$emit("update:clientValidationError", this.validate(updatedValue));
    },
    maxValue(max) {
      if (!this.isDisabled && new Date(max.setHours(0, 0, 0, 0)) < new Date(this.value)) {
        this.selectedValue = null;
      }
    }
  },
  mounted() {
    if (this.selectedValue == null || this.selectedValue == "0001-01-01") {
      this.selectedValue = null
    }

    this.$emit("update:value", this.selectedValue);
    this.$emit("update:clientValidationError", this.clientValidationError);
  },
  methods: {
    validate(val: string | null) {
      if (val == null || val == undefined || val == "" || val == "0001-01-01") {
        return "Answer required";
      }

      return null;
    },
    formatDate(date: Date) {
      return [date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)].join('-');
    },
    handleDatePickerOpen() {
      this.showChevron = false;
    },
    handleDatePickerClose() {
      this.showChevron = true;
    },
    handleDatePickerInputCleared() {
      // Text input cleared, should show chevron again
      this.showChevron = true;
    }
  },
});
