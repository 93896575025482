
import { BIconCheckLg } from "bootstrap-icons-vue";
import VerticalNavSubList from "./VerticalNavSubList.vue";
import { mapActions, mapState } from 'pinia';
import { navStore } from '@/stores/NavStore';
import { PropType, defineComponent } from "vue";
import { VerticalNavItem } from "@/interfaces/verticalInsuranceNavSubList/VerticalNavItem";
import { ScreenWidth } from '@/enums/ScreenWidth.enum';

export default defineComponent({
  name: "VerticalInsuranceStepNavigator",
  components: {
    VerticalNavSubList,
    BIconCheckLg,
  },
  props: {
    activeItem: {type: String, required: true},
    screenWidth: {type: String as PropType<ScreenWidth>, required: true},
  },
  data() {
    return {
      ScreenWidth: ScreenWidth,
    }
  },
  computed: {
    ...mapState(navStore, ['navItems']),
  },
  methods: {
    ...mapActions(navStore, ['isItemActive']),
    isActive(activeItem: string, item: VerticalNavItem) {
      return this.isItemActive(activeItem, item);
    },
  },
});
