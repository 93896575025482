
import { PropType, defineComponent } from "vue";
import PaymentDetailsCard from "../tabs/debit-payment-tab/PaymentDetailsCard.vue";
import ConditionsOfThisAuthority from '@/components/terms-and-conditions/conditions-of-this-authority/ConditionsOfThisAuthority.vue';
import SpecificConditions from '@/components/terms-and-conditions/specific-conditions/SpecificConditions.vue';
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { Payment } from "@/interfaces/payment/Payment";
import { PolicyBenefitPremium } from "@/interfaces/payment/PolicyBenefit";
import { mapActions } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore";

export default defineComponent({
  name: "DebitPaymentConfirmation",
  components: {
    ConditionsOfThisAuthority,
    PaymentDetailsCard,
    SpecificConditions
  },
  props: {
    policy: { type: Object as PropType<InsurancePolicy>, required: true },
    payment: { type: Object as PropType<Payment>, required: true },
    existingPremiums: { type: Array as PropType<PolicyBenefitPremium[]>, default: () => []},
    validationError: { type: String, default: "" }
  },
  emits: ["agreeChange"],
  data() {
    return {
      doesAgree: false,
    };
  },
  computed: {
    existingPremiumSum(): number {
      return this.getPremiumAmountTotalByFrequency(this.existingPremiums, this.payment.frequency);
    },
    premiumAmount(): number {
      const premiumBenefitsFlattened = this.flattenedPolicyBenefitPremiums(this.policy.policyBenefits);
      const premiumTotalByFrequency = this.getPremiumAmountTotalByFrequency(premiumBenefitsFlattened, this.payment.frequency);

      return premiumTotalByFrequency + this.existingPremiumSum;
    },
    shouldShowPaymentDetailsCard(): boolean {
      return this.showPaymentDetailsCard(
        this.payment.frequency,
        this.premiumAmount,
        this.policy.policyType,
        this.policy.firstPremiumDate,
        this.policy.nextPremiumDate
      )
    }
  },
  watch: {
    doesAgree() {
      this.$emit("agreeChange", this.doesAgree);
    },
  },
  methods: {
    ...mapActions(insuranceStore, ['flattenedPolicyBenefitPremiums', 'getPremiumAmountTotalByFrequency', 'showPaymentDetailsCard']),
  },
});
