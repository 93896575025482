import { defineStore } from "pinia";

export const navStore = defineStore("nav", {
  state: () => {
    return {
      navItems: [] as any,
    };
  },
  actions: {
    updateNavItemCheckedStatus(navItem: string) {
      this.navItems.forEach((navData: { text: String; isChecked: Boolean }) => {
        if (navData.text === navItem) {
          navData.isChecked = true;
        }
      });
    },
    checkStaticPageStatus(name: string): Boolean {
      for (let idx = 0; idx < this.navItems.length; idx++) {
        if (this.navItems[idx].text === name) {
          return this.navItems[idx].isChecked;
        }
      }
      return false;
    },
    checkSectionCompleted(section: any): Boolean {
      if (section && section.isCompleted) {
        return true;
      }

      if (section && section.sections && Object.keys(section.sections).length > 0) {
        const completedSections = Object.values(section.sections).filter((s) => {
          return this.checkSectionCompleted(s);
        });

        return completedSections?.length == Object.values(section.sections).length;
      }

      return false;
    },
    generateNavDataFromQuestionnaire(questionnaireData: any, navDepthNumber = 1) {
      const navData: any = [];
      for (const externalRefId in questionnaireData) {
        const section = questionnaireData[externalRefId];
        const item: any = {
          id: section.externalReferenceId,
          text: section.title,
          link: section.path,
          isChecked: this.checkSectionCompleted(section) ?? false,
        };

        if (
          section.sections &&
          Object.keys(section.sections) &&
          Object.keys(section.sections).length > 0
        ) {
          item.subItems = this.generateNavDataFromQuestionnaire(
            section.sections,
            navDepthNumber + 1
          );

          // hide the subitem according to the nav depth limitation.
          if (navDepthNumber >= process.env.VUE_APP_NAV_DEPTH) {
            item.hideSubItems = true;
          }
        }
        navData.push(item);
      }
      return navData;
    },
    isItemActive(activeItem: string, item: any) {
      if (item.id && item.id == activeItem) {
        return true;
      }

      // Handles non-questionnaire pages
      if (item.id == activeItem) {
        return true;
      }

      const subItems = item.subItems;
      if (!subItems || subItems.length <= 0) {
        return false;
      }

      for (let i = 0; i < subItems.length; i++) {
        const subItem = subItems[i];
        if (this.isItemActive(activeItem, subItem)) {
          return true;
        }
      }

      return false;
    },
    getNavData(
      questionnaireData: any,
      dataGeneratorConfigs: any,
      queryParams: any,
      navDepthNumber = 1
    ) {
      const queryParamsMapped = Object.entries(queryParams).map(
        ([k, v]: any) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
      const queryString = "?" + queryParamsMapped.join("&");

      const questionnaireNavData = this.generateNavDataFromQuestionnaire(
        questionnaireData,
        navDepthNumber
      );

      // Set the summary page to completed if all other sections have been completed
      questionnaireNavData[1].subItems.filter((x: any) => x.id == "Medical_Summary")[0].isChecked =
        this.checkAllChecked(questionnaireNavData);

      const configNavData = dataGeneratorConfigs.topLevelSections.reduce(
        (result: any, sect: any) => {
          if (sect.static === true) {
            const link = `/${dataGeneratorConfigs.boosterPageUrlPrefix}/${sect.prefix}`;

            const nav = {
              id: sect.prefix,
              text: sect.name,
              link: link + queryString,
              isChecked: this.checkStaticPageStatus(sect.name) ?? false,
            };
            result.push(nav);
          }
          return result;
        },
        []
      );
      this.navItems = [...questionnaireNavData, ...configNavData];
    },
    checkAllChecked(navData: Array<any>) {
      let isAllChecked = true;
      for (let i = 0; i < navData.length; i++) {
        // Skip checking each section if everything is checked
        if (navData[i].isChecked) {
          continue;
        }

        const subItems = navData[i].subItems;

        for (let j = 0; j < subItems.length; j++) {
          const subSection = subItems[j];

          if (!subSection.isChecked && subSection.id != "Medical_Summary") {
            isAllChecked = false;
          }
        }
      }

      return isAllChecked;
    },
  },
});
