
import { PropType, defineComponent } from "vue";
import { QuoteSectionItem } from "@/interfaces/quoteCard/QuoteSectionItem";

export default defineComponent({
  name: "QuoteSection",
  props: {
    productTitle: { type: String, required: true },
    coverAmountTitle: { type: String, required: true },
    premiumAmountTitle: { type: String, required: true },
    isDarkBlue: { type: Boolean, default: false },
    isMobileScreen: { type: Boolean, default: false },
    items: { type: Array as PropType<QuoteSectionItem[]>, required: true },
    frequency: { type: String, required: true }
  }
});
