
import { defineComponent } from "vue";
import { ScreenWidth } from "../../enums/ScreenWidth.enum";

export default defineComponent({
  name: "PopupBox",
  props: {
    title: {type: String, default: ""},
    content: {type: String, default: ""},
    okay: {type: String, default: "Okay"},
    secondaryText: {type: String, default: ""},
    showPopupBox: {type: Boolean, default: false},
    screenWidth: {type: String, required: true}
  },
  emits: ["update:showPopupBox", "primaryBtnAction"],
  computed: {
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    }
  },
  methods: {
    closePopupBox() {
      this.$emit("primaryBtnAction");
      this.$emit("update:showPopupBox", false);
    },
    closePopupBoxOnSecondaryBtn() {
      this.$emit("update:showPopupBox", false);
    }
  }
});
