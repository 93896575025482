
import { PropType, defineComponent } from "vue";
import { ScreenWidth } from "../../enums/ScreenWidth.enum";

export default defineComponent({
  name: "BoosterNav",
  props: {
    screenWidth: {type: String as PropType<ScreenWidth>, required: true}
  },
  computed: {
    isMobileScreen() {
      return [ScreenWidth.Mobile, ScreenWidth.Small].includes(this.screenWidth);
    },
    isMediumScreen() {
      return this.screenWidth === ScreenWidth.Medium;
    },
    isLargeScreen() {
      return this.screenWidth === ScreenWidth.Large;
    },
    isWiderScreen() {
      return this.screenWidth === ScreenWidth.XLarge;
    }
  }
});
