
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "BMICalculationItemHeight",
  props: {
    title: {type: String, default: ""},
    prompt: {type: String, default: ""},
    value: {type: [String, Number, null] as PropType<String | number | null>, default: null},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    isSmallScreen: {type: Boolean, default: false}
  },
  emits: ["update:value"],
  data() {
    return {
      units: [
        {
          value: "M",
          text: "m",
        },
        {
          value: "Ft",
          text: "ft",
        },
      ],
      selectedUnit: "M",
      returnedMetres: this.value == null ? null : Number(this.value),
      metres: this.value == 0 ? null : this.value == null ? null : Number(this.value),
      feet: 0,
      inches: 0,
      heightUnknown: false,
      inputDisable: false,
    };
  },
  watch: {
    heightUnknown(newVal) {
      this.inputDisable = newVal;
      this.emitMetres();
    },
    metres() {
      this.returnedMetres = this.metres;
      this.emitMetres();
    },
    feet() {
      this.returnedMetres = this.feet * 0.3048 + this.inches * 0.0254;
      this.emitMetres();
    },
    inches() {
      this.returnedMetres = this.feet * 0.3048 + this.inches * 0.0254;
      this.emitMetres();
    },
  },
  mounted() {
    if (this.value == 0) {
      this.heightUnknown = true;
    }
    if (this.value == null) {
      this.metres = null;
    }
  },
  methods: {
    clickEventHandler(unit: any) {
      if (this.selectedUnit == unit.value) {
        return;
      }
      this.selectedUnit = unit.value;
      if (this.returnedMetres == null) {
        return;
      }
      if (isNaN(this.returnedMetres)) {
        this.metres = null;
        this.returnedMetres = null;
        this.feet = 0;
        this.inches = 0;
        return;
      }
      if (this.selectedUnit == "Ft") {
        this.feet = Math.floor(this.returnedMetres / 0.3048);
        this.inches = Math.round(((this.returnedMetres / 0.0254) % 12) * 100) / 100;
      } else if (this.selectedUnit == "M") {
        this.metres = Math.round(this.returnedMetres * 100) / 100;
      }
      this.emitMetres();
    },
    emitMetres() {
      this.returnedMetres = this.returnedMetres == null ? null : Math.round(this.returnedMetres * 100) / 100;
      this.$emit("update:value", this.heightUnknown ? "0" : this.returnedMetres?.toString());
    },
  },
});
