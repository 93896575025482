
import { PropType, defineComponent } from "vue";
import SelectInput from "@/components/select-input/SelectInput.vue";
import QuoteSection from "./QuoteSection.vue";
import { PaymentSummaryFrequencyDivider as PaymentSummaryFrequencyDivider } from "@/enums/FrequencyDivider.enum";
import { InsuranceCover } from "@/interfaces/productQuote/InsuranceCover";
import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import { QuoteSectionItem } from "@/interfaces/quoteCard/QuoteSectionItem";
import RoundingUtility from "@/utils/RoundingUtility";
import { insuranceStore } from "@/stores/InsuranceStore";
import { mapActions } from "pinia";

export default defineComponent({
  name: "QuoteCard",
  components: {
    QuoteSection,
    SelectInput
  },
  props: {
    products: { type: Array as PropType<InsuranceCover[]>, required: true },
    existingProducts: {type: Array as PropType<PolicyBenefit[]>, default: () => null},
    isMobileScreen: {type: Boolean, required: true},
    showNotes: { type: Boolean, default: false }
  },
  data() {
    return {
      mobileScreen: false,
      errorMsg: "",
      frequency: "monthly",
      loading: true,
      isConfirmationPage: false,
      frequencies: [
        { value: "weekly", text: "week" },
        { value: "fortnightly", text: "fortnight" },
        { value: "monthly", text: "month" },
        { value: "yearly", text: "year" },
      ],
      doesAgree: false,
      paymentFrequencyError: undefined as string | undefined
    }
  },
  computed: {
    premiumAmount(): string {
      const frequencyDivider = PaymentSummaryFrequencyDivider[this.frequency as keyof typeof PaymentSummaryFrequencyDivider];

      const productCostsByFrequency = this.products.map((product: InsuranceCover) => RoundingUtility.round(product.monthlyCost * 12 / frequencyDivider));
      const productCostSum = productCostsByFrequency.reduce((sum, productCost) => sum + productCost, 0);

      let existingPremiumSumByFrequency = 0;
      if(this.existingProductsExist) {
        existingPremiumSumByFrequency = this.existingProducts
          // Divide yearly amount and round to 2dp
          .map((existingProduct) => RoundingUtility.round((existingProduct?.yearlyPremiumAmount ?? 0) / frequencyDivider))
          .reduce((sum, existingPremiumAmount) => sum + existingPremiumAmount, 0);
      }

      const totalAmount = productCostSum + existingPremiumSumByFrequency;
      return totalAmount.toFixed(2);
    },
    notesText() {
      let txt = "In the case of special events or inflation indexing";
      this.products.forEach((product) =>
      txt = txt + ", your " + (product.name.includes('Booster SmartCover') ? product.name.slice(19): product.name) + " has been approved up to $" + product.maxCover.toLocaleString()
      );
      txt = txt + ".";
      return txt;
    },
    frequencyWithProperCase() {
      return this.frequency.charAt(0).toUpperCase().concat(this.frequency.slice(1,this.frequency.length));
    },
    existingProductsItems(): QuoteSectionItem[] {
      // Mapped into simple QuoteSectionItem
      if(!this.existingProducts || this.existingProducts.length == 0){
        return [];
      }

      const frequency = this.frequency.toLocaleUpperCase() === 'YEARLY' ? 'Annually' : this.frequency;

      return this.existingProducts.map(product => {
        const policyBenefitPremiumSum = this.getPremiumAmountTotalByFrequency(product.policyBenefitPremiums, frequency);

        return {
          productName: product.benefit.benefitName,
          coverAmount: product.coverAmount,
          premiumAmount: policyBenefitPremiumSum
        }
      });
    },
    productsItems(): QuoteSectionItem[] {
      return this.products.map(product => {
        return {
          productName: product.name,
          productDetailTitle: product.amendments && product.amendments.length > 0 ? "Policy amendments:" : undefined,
          productDetails: product.amendments,
          coverAmount: product.coverAmount,
          premiumAmount: this.userPayments(product.monthlyCost * 12)
        }
      });
    },
    existingProductsExist(): boolean {
      return !!this.existingProducts && this.existingProducts.length > 0;
    }
  },
  methods: {
    ...mapActions(insuranceStore, ['flattenedPolicyBenefitPremiums', 'getPremiumAmountTotalByFrequency']),
    userPayments(yearlyPremiumAmount: number) {
      return yearlyPremiumAmount /
        PaymentSummaryFrequencyDivider[this.frequency as keyof typeof PaymentSummaryFrequencyDivider];
    }
  }
 });
