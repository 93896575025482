export default class FileUtility {
  static async base64ToArrayBuffer(base64: any) {
    const binarystring = window.atob(base64);
    const binarylen = binarystring.length;
    const bytes = new Uint8Array(binarylen);
    for (let i = 0; i < binarylen; i++) {
      const ascii = binarystring.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  static async saveByteArray(data: any, name: string) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blob = new Blob(data, { type: "application/pdf" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  static async downloadFile(content: any, fileName: string) {
    const bytecharacters = await FileUtility.base64ToArrayBuffer(content);
    await FileUtility.saveByteArray([bytecharacters], fileName);
  }
}
