
import { Option } from "@/interfaces/option-item/Option";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    value: { type: String, default: "" },
    options: { type: Array as PropType<Option[]> | PropType<String[]>, required: true },
    validationError: { type: String, default: "" },
    isDisabled: { type: Boolean, default: false }
  },
  emits: ["update:value"],
  data() {
    return {
      selectedValue: this.value,
    };
  },
  computed: {
    castedOptions() {
      if (typeof(this.options[0]) == 'object') {
        return this.options as Option[];
      }

      return (this.options as String[]).map((option: String) => {
        return {
          value: option,
          text: option
        } as Option;
      });
    }
  },
  watch: {
    selectedValue() {
      this.$emit("update:value", this.selectedValue);
    },
  },
});
