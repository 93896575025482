
import { PropType, defineComponent } from "vue";
import { SingleChoiceQuestion } from "@/interfaces/singlechoicequestionitem/SingleChoiceQuestion";
export default defineComponent({
  name: "SingleChoiceQuestionItem",
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    options: {type: Array as PropType<SingleChoiceQuestion[]>, required: true},
    value: {type: String as PropType<String | null>, default: null},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    isSmallScreen: {type: Boolean, default: false}
  },
  emits: ["update:value"],
  data() {
    return {
      selectedValue: this.value,
    };
  },
  methods: {
    clickEventHandler(option: SingleChoiceQuestion) {
      if (option.value == this.selectedValue) {
        this.selectedValue = null;
      } else {
        this.selectedValue = option.value;
      }

      this.$emit("update:value", this.selectedValue);
    },
  },
});
