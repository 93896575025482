
import { PropType, defineComponent } from "vue";
import VueMultiselect from "vue-multiselect";
import { DropdownOption } from "@/interfaces/DropdownOption";

export default defineComponent({
  name: "SearchDropdown",
  components: {
    VueMultiselect,
  },
  props: {
    title: {type: String, default: ""},
    description: {type: String, default: ""},
    options: {type: Array as PropType<DropdownOption[]>, required: true},
    value: {type: [String, Array], default: ""},
    placeholder: {type: String, default: ""},
    multiselect: {type: Boolean, default: false},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    noneText: {type: String, default: ""},
    isSmallScreen: {type: Boolean, default: false},
    searchAddress: {type: Boolean, default: false},
    isLoading: {type: Boolean, default: false}
  },
  emits: ["update:value", "searchQuery"],
  data() {
    return {
      searchQuery: "",
      placeholderValue: this.placeholder
    };
  },
  computed: {
    selectedOption: {
      get() {
        if (this.multiselect) {
          return (
            this.options?.filter((x:DropdownOption) => this.value?.includes(x.value)) ?? []
          );
        } else {
          if (this.searchAddress) {
            let filteredResult: any = this.options.find((x: any) => x.value.addressLine1 == this.value);
            if (filteredResult?.text) {
              filteredResult.text = filteredResult?.value?.addressLine1
            } else if (this.value) {
              filteredResult = {text: this.value};
            }
            return filteredResult;
          }
          return this.options?.find((x:DropdownOption) => x.value == this.value);
        }
      },
      set(option:DropdownOption | DropdownOption[]) {
        if (this.multiselect && Array.isArray(option)) {
          this.$emit(
            "update:value",
            option?.map((x:DropdownOption) => x.value),
          );
        }
        else if (!Array.isArray(option)) {
          this.$emit("update:value", option?.value);
        }
      },
    },
    filteredOptions() {
      if (this.searchAddress) {
        return this.options;
      }
      // If length of options is greater than 1000, for the user to search for options to show.
      // 1000 is the default max number of options that will be displayed in dropdown.
      if (this.options.length > 1000 && this.searchQuery == "") {
        return [];
      }
      let sorted = this.options
        .filter((option:DropdownOption) =>
          option.text.toLowerCase().includes(this.searchQuery.toLowerCase())
          && option.text != this.noneText,
        )
        .sort((a:DropdownOption, b:DropdownOption) => a.sequence - b.sequence);

      var noneOption = this.options.find((x:DropdownOption)  => x.text == this.noneText) ?? new Object as DropdownOption;
      if (Object.keys(noneOption).length > 0) {
        sorted.unshift(noneOption);
      }
      return sorted;
    },
  },
  methods: {
    onSearchChange(searchQuery: string) {
      this.searchQuery = searchQuery;
      if (this.searchAddress) {
        this.$emit("searchQuery", searchQuery);
      }
    },
    onOpen() {
      this.placeholderValue = "";
    },
    onClose() {
      this.placeholderValue = this.placeholder;
    }
  },
});
