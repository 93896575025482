<template>
  <div class="position-relative d-flex m-2" :class="previousPath? 'mb-0 ': ''">
    <router-link
      v-if="previousPath || previousPathForStaticPages && activeItem != 'ProductQuote'"
      :to="previousPath? previousPath: previousPathForStaticPages"
      ><i class="bi bi-chevron-left back-icon pe-3"></i
    ></router-link>

    <ul
      v-if="navItems.length > 0"
      class="nav justify-content-center nav-fill d-flex flex-nowrap align-items-center"
      :class="{'ps-3': !previousPath && !previousPathForStaticPages || activeItem == 'ProductQuote'}">
      <li v-for="(item, index) in navItems" :key="index" class="nav-item d-flex flex-nowrap">
        <span
          class="circle me-0 ms-0"
          :class="{'bg-light-gray': (!item.isChecked && !isActive(activeItem, item)) }">
          <BIconCheckLg v-if="item.isChecked" class="circle-icon" />
          <span
            v-else
            class="circle-font"
            :class="{'unchecked-text': !item.isChecked, 'text-white': isActive(activeItem, item)}"
            >{{ index + 1 }}
          </span>
        </span>
        <span
          class="me-0 ms-0"
          :class="{'progress' : index + 1 < 7, 'active-bg': showActiveProgress(activeItem, item, index) }"></span>
      </li>
    </ul>
  </div>
  <ul
    v-if="navItems.length > 0"
    class="nav justify-content-center nav-fill d-flex flex-nowrap align-items-center ms-2 me-2"
    :class="{'ps-3': previousPath || previousPathForStaticPages}">
    <span v-if="previousPath || previousPathForStaticPages" class="ps-3"></span>
    <li
      v-for="(item, index) in navItems"
      :key="index"
      class="nav-item d-flex flex-nowrap justify-content-center ps-3"
      :class="{'pe-4': index === navItems.length - 1}">
      <span
        v-if="isActive(activeItem, item)"
        class="item-label-alt label-text"
        >{{ item.text }}</span
      >
    </li>
  </ul>
  <template v-for="(item, index) in navItems" :key="index">
    <div
      v-if="isActive(activeItem, item) && item.subItems && item.subItems.length > 0"
      class="pt-4">
      <HorizontalNavSubList :items="item.subItems" @dropdown-toggled="$emit('dropdownToggled')" />
    </div>
  </template>
</template>

<script>
import { BIconCheckLg } from "bootstrap-icons-vue";
import HorizontalNavSubList from "./HorizontalNavSubList.vue";
import { mapActions, mapState } from 'pinia';
import { navStore } from '@/stores/NavStore';
import { routerStore } from "@/stores/RouterStore";

export default {
  name: "HorizontalInsuranceStepNavigator",
  components: {
    HorizontalNavSubList,
    BIconCheckLg,
  },
  props: {
    activeItem: {type: String, required: true},
    previousPath: {type: [String, null], required: true}
  },
  emits: ['dropdownToggled'],
  data() {
    return {
      previousPathForStaticPages: null
    }
  },
  computed: {
    ...mapState(navStore, ['navItems']),
  },
  created() {
    this.previousPathForStaticPages = this.getPage(-1, this.$route.path, this.$route.query);
  },
  methods: {
    ...mapActions(navStore, ['isItemActive']),
    ...mapActions(routerStore, ["getPage"]),
    isActive(activeItem, item) {
      return this.isItemActive(activeItem, item);
    },
    showActiveProgress(activeItem, item, index) {
      const activeItemIdx = this.navItems.findIndex(x => x.id === activeItem);
      if (activeItemIdx === -1) {
        return item.isChecked && !this.isItemActive(activeItem, item);
      }
      return item.isChecked && index < activeItemIdx;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import "./HorizontalInsuranceStepNavigator.scss";
</style>
