
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import CliConfirmationTab from "@/components/tabs/confirmation-tab/CliConfirmationTab.vue";
import SsliConfirmationTab from "@/components/tabs/confirmation-tab/SsliConfirmationTab.vue";
import ProductQuoteDataGenerator from "@/data-generator/ProductQuoteDataGenerator";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";

export default defineComponent({
  name: "ConfirmationTab",
  components: {
    CliConfirmationTab,
    SsliConfirmationTab,
    LoadingSpinnerWithBackground
  },
  props: {
    screenWidth: {type: String, required: true}
  },
  data() {
    return {
      isPolicyReferred: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
    isMobileScreen() {
      return this.screenWidth === ScreenWidth.Mobile;
    },
    isDDNotBankAccountHolder() {
      return this.policyInfo.policy.paymentConfigurations[0].method == 'Direct debit' &&
      this.policyInfo.policy.paymentConfigurations[0].directDebit?.email != null &&
      this.policyInfo.insuredPerson.email != this.policyInfo.policy.paymentConfigurations[0].directDebit?.email
    }
  },
  async mounted() {
    this.isLoading = true;
    let dataGenerator = new ProductQuoteDataGenerator();
    let policyData = await dataGenerator.getPolicySchedule();
    this.isPolicyReferred = this.checkPolicyReferred(policyData);
    this.isLoading = false;
  },
  methods: {
    checkPolicyReferred(policyData: any) {

      let isReferred = false;
      if (this.policyInfo.policy.policyType == "1")
      {
         isReferred = ProductQuoteDataGenerator.referredStatusListSSLI.includes(policyData.policyStatusName);
      }
      else
      {
        isReferred = ProductQuoteDataGenerator.referredStatusListCLI.includes(policyData.policyStatusName);
      }

      if (!isReferred) {
        isReferred = this.policyInfo.policy.policyBenefits.some(policyBenefit => policyBenefit.quircPolicy.note?.length > 0);
      }
      return isReferred;
    },
    openAdviserHub() {
      if (this.policyInfo.policy.policyType == "1") {
        window.location.href = process.env.VUE_APP_MEM_POR_URL;
      } else {
        window.location.href = process.env.VUE_APP_ADV_HUB_URL;
      }
    }
  }
});
