
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TextInputSmall",
  props: {
    title: {type: String, default: ""},
    value: {type: [String, Number] as PropType<String | Number>, default: ""},
    placeholder: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    validationError: {type: String, default: ""},
    description: {type: String, default: ""},
    inputClass: {type: String, default: ""}
  },
  emits: ["update:value"],
  data() {
    return {
      enteredValue: this.value,
    };
  },
  watch: {
    enteredValue(newValue) {
      this.$emit("update:value", newValue.toString());
    },
  },
});
