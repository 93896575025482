<template>
  <div class="content">
    <div class="row justify-content-center app-content">
      <div class="content-col">
        <div class="row">
          <div
            v-if="!isSmallScreen && isTabLoadComplete"
            class="col-lg-3 col-md-3 col-sm-12 side-col pt-5"
            :class="screenWidth === ScreenWidth.Small ? 'ms-5' : 'ps-5 me-2'">
            <VerticalInsuranceStepNavigator
              :active-item="getActiveItem()"
              :screen-width="screenWidth" />
          </div>
          <div
            v-if="isSmallScreen && isTabLoadComplete"
            class="col-lg-3 col-md-3 col-sm-12 me-2 horizontal-bar">
            <HorizontalInsuranceStepNavigator
              :active-item="getActiveItem()"
              :previous-path="previousPagePath"
              @dropdown-toggled="onDropdownToggled()" />
          </div>
          <div
            class="col-lg-8 col-md-8 col-sm-12 content-col p-4 position-relative"
            :class="
              isSmallScreen ? (isTabLoadComplete && isGeneralTab() ? 'pt-0' : '') : 'pt-5'
            ">
            <div
              class="dropdown-overlay position-absolute w-100 h-100"
              :class="{ 'd-none': !isSmallScreen || !isDropdownExpanded }"></div>
            <QuircGeneralTab
              v-if="isTabLoadComplete && isGeneralTab()"
              :key="tabRerender"
              :questions="questionVMs?.questions"
              :title="questionVMs?.title"
              :next-path="nextPagePath"
              :previous-path="previousPagePath"
              :section-disclosures="sectionDisclosureItems ?? []"
              :summary="summary"
              :reload-questions-handler="loadData"
              :screen-width="screenWidth" />

            <router-view
              v-else-if="isTabLoadComplete && !isGeneralTab()"
              :screen-width="screenWidth"></router-view>

            <div v-else class="load-spinner" role="status">
              <span class="visually-hidden">Spinner</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isGeneratorComplete" class="position-absolute mt-5 bg-white">
        <LoadingSpinner />
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalInsuranceStepNavigator from "@/components/horizontal-insurance-step-navigator/HorizontalInsuranceStepNavigator.vue";
import VerticalInsuranceStepNavigator from "@/components/vertical-insurance-step-navigator/VerticalInsuranceStepNavigator.vue";
import QuestionnaireDataAccelerator from "@/data-generator/QuestionnairDataAccelerator";
import QuestionnaireDataGenerator from "@/data-generator/QuestionnaireDataGenerator.js";
import SynopsisCardDataGenerator from "@/data-generator/SynopsisCardDataGenerator";
import QuircGeneralTab from "@/components/tabs/quirc-general-tab/QuircGeneralTab.vue";
import InsuranceQuestionnaire from "@/components/pages/questionnaire-page/InsuranceQuestionnaire.js";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner.vue";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import { dataGeneratorConfigs } from "@/data-generator/DataGeneratorConfigs";
import { mapActions, mapState } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore.ts";
import { paymentStore } from "@/stores/PaymentStore";
import { routerStore } from "@/stores/RouterStore";
import { navStore } from "@/stores/NavStore";
import { popupStore } from "@/stores/PopupStore";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";

export default {
  name: "QuestionnairePage",
  components: {
    LoadingSpinner,
    QuircGeneralTab,
    VerticalInsuranceStepNavigator,
    HorizontalInsuranceStepNavigator,
  },
  props: {
    screenWidth: { type: String, required: true },
  },
  data() {
    return {
      currentRoutePath: "/",
      questionVMs: {},
      nextPagePath: null,
      previousPagePath: null,
      isGeneratorComplete: false,
      isTabLoadComplete: false,
      sectionDisclosureItems: [],
      summary: {},
      tabRerender: 0,
      isDropdownExpanded: false,
      ScreenWidth: ScreenWidth,
    };
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
    ...mapState(navStore, ["navItems"]),
    isSmallScreen() {
      return [ScreenWidth.Mobile, ScreenWidth.Small].includes(this.screenWidth);
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    ...mapActions(paymentStore, ["generatePaymentNavData"]),
    ...mapActions(routerStore, ["getPrefixAndTabName", "getTopLevelSectionsByPath"]),
    ...mapActions(navStore, ["updateNavItemCheckedStatus", "getNavData"]),
    ...mapActions(popupStore, ["showErrorMsg", "showWarningMsg", "showInfoMsg"]),
    navToFirstIncompletePage(applicationSubmitted, policyBenefitStatus) {
      if (
        policyBenefitStatus === BenefitStatus.Accepted ||
        policyBenefitStatus === BenefitStatus.WaitingForConfirmation
      ) {
        this.$router.push(this.navItems.find((x) => x.id == "Confirmation").link);
        return false;
      }

      // Redirect to next uncompleted page if questionnaire has been completed
      if (applicationSubmitted && this.isGeneralTab()) {
        for (let i = 2; i < this.navItems.length; i++) {
          if (this.navItems[i].isChecked == false) {
            this.$router.push(this.navItems[i].link);
            return true;
          }
        }
      }

      return false;
    },
    reRenderTab() {
      this.tabRerender++;
    },
    async loadData(waitForQuestions = false) {
      // Load the profile data in the backend to get the full info
      // This can accelerate the later section load
      QuestionnaireDataAccelerator.getProfileToCache();

      // Fetch and generate questionnaire data
      let currentRoutePath = this.$router.currentRoute.value.path;
      let policyBenefitStatus = this.policyInfo.policy.policyBenefits[0].benefitStatus;
      let applicationSubmitted =
        this.policyInfo.policy.policyBenefits[0].quircPolicy.quircStatus != null;

      let dataGenerator = new QuestionnaireDataGenerator(
        dataGeneratorConfigs,
        this.$route.query
      );
      let { questionnaireData, questionLoadPromises, submittedSumInsured } = await dataGenerator.generate(
        applicationSubmitted
      );

      this.policyInfo.submittedSumInsured = submittedSumInsured;

      const synopsisGenerator = new SynopsisCardDataGenerator(questionnaireData);
      let sectionRelationshipMap = dataGenerator.getSectionRelationshipMap();
      let questionnaire = new InsuranceQuestionnaire(
        dataGeneratorConfigs,
        questionnaireData,
        sectionRelationshipMap
      );
      this.questionVMs = await questionnaire.getQuestionsByPath(currentRoutePath);

      // Hide the loading spinner after generator complete
      this.isGeneratorComplete = true;

      // When no section could be found
      // TODO: fix default page logic this may need clean up
      if (this.questionVMs === null && this.isGeneralTab()) {
        // Go to default page
        let defaultPath = questionnaire.getDefaultPath();
        this.$router.push(defaultPath);
        return;
      }

      this.getNavData(questionnaireData, dataGeneratorConfigs, this.$route.query);

      // check menu items and update its completion status
      this.checkMenuItemsStatus(applicationSubmitted);

      if (this.navToFirstIncompletePage(applicationSubmitted, policyBenefitStatus)) {
        return;
      }

      this.summary = questionnaireData;

      if (!this.isGeneralTab()) {
        this.isTabLoadComplete = true;
        return;
      }

      this.previousPagePath = questionnaire.getPreviousPath();
      this.nextPagePath = questionnaire.getNextPath();

      // When all questions loaded
      Promise.all(Object.values(questionLoadPromises)).then(
        (() => {
          // Regenerate navItems when all questions loaded
          this.getNavData(questionnaireData, dataGeneratorConfigs, this.$route.query);
          this.checkMenuItemsStatus(applicationSubmitted);

          // Generate medical condition disclosures
          synopsisGenerator.getSynopsisList().then((sectionDisclosures) => {
            this.sectionDisclosureItems = sectionDisclosures?.map((disclosure) => {
              return {
                name: disclosure.disclosureName,
                description: disclosure.disclosureDescription,
              };
            });
          });

          // Rerender tab to refresh data
          this.reRenderTab();
        }).bind(this)
      );

      if (
        !this.questionVMs?.currentTabId ||
        !questionLoadPromises[this.questionVMs.currentTabId]
      ) {
        return this.questionVMs;
      }

      // Load the current tab data
      if (waitForQuestions) {
        await Promise.all(questionLoadPromises[this.questionVMs.currentTabId]);
        this.questionVMs = await questionnaire.getQuestionsByPath(currentRoutePath);

        // Rerender tab to refresh data
        this.reRenderTab();
        this.isTabLoadComplete = true;
      } else {
        Promise.all(questionLoadPromises[this.questionVMs.currentTabId]).then(
          (async () => {
            this.questionVMs = await questionnaire.getQuestionsByPath(currentRoutePath);

            // Rerender tab to refresh data
            this.reRenderTab();
            this.isTabLoadComplete = true;
          }).bind(this)
        );
      }

      return this.questionVMs;
    },
    isGeneralTab() {
      const path = this.$router.currentRoute.value.path;
      const topLevelSection = this.getTopLevelSectionsByPath(path);

      if (!topLevelSection) {
        return false;
      }

      return !topLevelSection.static;
    },
    getActiveItem() {
      if (this.questionVMs?.currentTabId) {
        return this.questionVMs?.currentTabId;
      }

      const path = this.$router.currentRoute.value.path;
      const prefixAndTabName = this.getPrefixAndTabName(path);

      if (!prefixAndTabName) {
        return null;
      }

      return prefixAndTabName.prefix;
    },
    checkMenuItemsStatus(applicationSubmitted) {
      if (!this.policyInfo?.policy?.policyBenefits?.length) {
        throw new Error("Abort: no policy benefits info received.");
      }

      // Checks based on data availability
      if (applicationSubmitted) {
        this.updateNavItemCheckedStatus("About you");
        this.updateNavItemCheckedStatus("Medical");
      }

      // check static pages and update its completion status
      if (this.policyInfo.policy.policyBenefits[0].policyBenefitPremiums?.length > 0) {
        this.updateNavItemCheckedStatus("Product and quote");
      }

      if (
        this.policyInfo.policy.policyBenefits[0].benefitStatus ===
          BenefitStatus.Accepted ||
        this.policyInfo.policy.policyBenefits[0].benefitStatus ===
          BenefitStatus.WaitingForConfirmation
      ) {
        this.updateNavItemCheckedStatus("Contact details");
        this.updateNavItemCheckedStatus("Policy ownership");
        this.updateNavItemCheckedStatus("Payment");
      }

      // Checks based on tab order
      const activeItem = this.getActiveItem();
      if (activeItem == "PolicyOwnership") {
        this.updateNavItemCheckedStatus("Contact details");
      }

      if (activeItem == "Payment") {
        this.updateNavItemCheckedStatus("Contact details");
        this.updateNavItemCheckedStatus("Policy ownership");
      }

      // For referred case
      if (
        activeItem == "Confirmation" &&
        this.policyInfo.policy.policyBenefits[0].benefitStatus === BenefitStatus.Referred
      ) {
        this.updateNavItemCheckedStatus("Contact details");
        this.updateNavItemCheckedStatus("Policy ownership");
        this.updateNavItemCheckedStatus("Payment");
      }
    },
    onDropdownToggled() {
      if ([ScreenWidth.Mobile, ScreenWidth.Small].includes(this.screenWidth)) {
        this.isDropdownExpanded = !this.isDropdownExpanded;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-col {
  max-width: 80rem;
}
.horizontal-bar {
  padding-top: 0.85rem;
}

.app-content {
  max-width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.dropdown-overlay {
  z-index: 999;

  top: 2px;
  left: 0;

  background: rgba(0, 0, 0, 0.5);
}
</style>
