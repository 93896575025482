
import { defineComponent } from "vue";
import DatePicker from "@/components/date-picker/DatePicker.vue";
import InsuranceClient from "@/rest-client/InsuranceClient";
import { popupStore } from "@/stores/PopupStore";
import { confirmationStore } from "@/stores/ConfirmationUAPCStore";
import { mapActions } from 'pinia';
import TextInputSmall from '@/components/text-input/TextInputSmall.vue';
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";

export default defineComponent({
  name: "DOBVerificationTab",
  components: {
    DatePicker,
    TextInputSmall,
    LoadingSpinnerWithBackground
  },
  props: {
    isMobileScreen: {type: Boolean, default: false}
  },
  data() {
    return {
      errors: {} as Record<string, any>,
      errorMsg: null as string | null,
      title: "Insured's date of birth",
      dateOfBirth: '',
      verificationCode: '',
      isLoading: false,
    }
  },
  mounted() {
    try {
      this.checkStoreToLoadPage();
    } catch (e) {
      this.isLoading = true;
      const popupBox = popupStore();
      if (e instanceof Error) {
        popupBox.showErrorMsg(e.message);
      }
    }
  },
  methods: {
    ...mapActions(confirmationStore, ['setConfirmationItemAsChecked', 'checkConfirmationPageStatus', 'getNextPage']),
    checkStoreToLoadPage() {
      if (this.checkConfirmationPageStatus("InsuranceWebUAPCDOBVerification")) {
        const popupBox = popupStore();
        popupBox.showErrorMsg("Date of birth already verified!");
        throw new Error('Date of birth already verified!');
      }
    },
    readError(field: string) {
      if (!this.errors) {
        return undefined;
      }
      return this.errors[field];
    },
    validate() {
      this.errorMsg = null;
      this.errors = {};

      if (!this.dateOfBirth) {
        this.errors['dateOfBirth'] = 'Answer required';
      }

      if (!this.verificationCode) {
        this.errors['verificationCode'] = 'Answer required';
      }
      return this.errors && Object.keys(this.errors).length == 0;
    },
    async goToNextPage() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      const response = await InsuranceClient.verifyDateOfBirth(this.dateOfBirth.substring(0, 10), this.verificationCode);

      if (response?.statusCode != 200) {
        this.errorMsg = 'Error happened while submitting data.';
        if (response?.statusCode == 400) {
          this.errorMsg += ' Please check submitted data.'
        }
      }

      if (!response?.body?.verified) {
        this.errorMsg = "Verification failed."
      }

      if (this.errorMsg) {
        const popupBox = popupStore();
        popupBox.showErrorMsg(this.errorMsg);
        this.isLoading = false;
      } else {
        this.setConfirmationItemAsChecked("InsuranceWebUAPCDOBVerification");
        const nextPath = this.getNextPage(this.$route.fullPath);
        if(nextPath) {
          this.$router.push(nextPath);
        }
      }

      this.isLoading = false;
      return;
    }
  }
});
