import { SynopsisItem } from "@/interfaces/SynopsisItem";
import InsuranceClient from "@/rest-client/InsuranceClient";

export default class SynopsisCardDataGenerator {
  questionnaireData: any;
  synopsisList: Array<SynopsisItem> = [];

  constructor(questionnaire: any) {
    this.questionnaireData = questionnaire;
  }

  pushSynopsisItem(item: SynopsisItem) {
    if (this.synopsisList.filter((s) => s.disclosureName == item.disclosureName).length > 0) {
      return;
    }

    this.synopsisList.push(item);
    this.synopsisList.sort((a, b) => a.disclosureName.localeCompare(b.disclosureName));
  }

  pushSynopsisItems(items: Array<SynopsisItem>) {
    if (!items || items.length <= 0) {
      return;
    }

    for (const item of items) {
      if (this.synopsisList.filter((s) => s.disclosureName == item.disclosureName).length > 0) {
        return;
      }

      this.synopsisList.push(item);
    }
    this.synopsisList.sort((a, b) => a.disclosureName.localeCompare(b.disclosureName));
  }

  findSectionInQuestionnaireData(sectionData: any, sectionExtRefId: string) {
    if (sectionData[sectionExtRefId]) {
      return sectionData[sectionExtRefId];
    }

    const currrentSections: Array<any> = Object.values(sectionData);

    for (const currrentSection of currrentSections) {
      if (!currrentSection.sections) {
        continue;
      }

      const subSections = currrentSection.sections;

      if (!subSections || Object.keys(subSections).length <= 0) {
        return null;
      }

      const found: any = this.findSectionInQuestionnaireData(subSections, sectionExtRefId);

      if (found) {
        return found;
      }
    }

    return null;
  }

  async pushConditionQuestions(sectionData: any) {
    const questions = sectionData?.questions;
    if (questions && questions.length > 0) {
      const filteredQuestions = questions.filter(
        (question: any) => question.answer.filter((a: any) => a.text == "Condition list").length > 0
      );

      if (filteredQuestions && filteredQuestions.length > 0) {
        for (const filteredQuestion of filteredQuestions) {
          await this.generateSynopsisItemsByCondition(filteredQuestion);
        }
      }
    }

    const subSections: Array<any> = Object.values(sectionData.sections);
    for (const subSection of subSections) {
      await this.pushConditionQuestions(subSection);
    }
  }

  pushExceptionalYesOrNoQuestions(sectionData: any, exceptionalExternalRefIds: Array<string>) {
    const sections: Array<any> = Object.values(sectionData);

    for (const section of sections) {
      const questions = section?.questions;
      if (questions && questions.length > 0) {
        const filteredQuestions = questions.filter((question: any) =>
          exceptionalExternalRefIds.includes(question.externalReferenceId)
        );

        if (filteredQuestions && filteredQuestions.length > 0) {
          for (const filteredQuestion of filteredQuestions) {
            const yesAnswer = filteredQuestion.answer.find((ans: any) => ans.text == "Yes");
            if (yesAnswer && filteredQuestion.value == yesAnswer.value) {
              this.pushSynopsisItem({
                disclosureName: filteredQuestion.title,
                disclosureDescription: "",
              });
            }
          }
        }
      }

      if (!section?.sections) {
        continue;
      }

      this.pushExceptionalYesOrNoQuestions(section.sections, exceptionalExternalRefIds);
    }
  }

  pushExceptionalMajorQuestion(sectionData: any, exceptionalExternalRefId: string) {
    const sections: Array<any> = Object.values(sectionData);

    for (const section of sections) {
      const questions = section?.questions;
      if (questions && questions.length > 0) {
        const filteredQuestions = questions.filter(
          (question: any) => exceptionalExternalRefId == question.externalReferenceId
        );

        if (filteredQuestions && filteredQuestions.length > 0) {
          const foundQuestion = filteredQuestions[0];
          if (!foundQuestion.isAnswered) {
            continue;
          }
          const items: Array<SynopsisItem> = foundQuestion.answer
            .filter(
              (ans: any) =>
                foundQuestion.value?.includes(ans.value) &&
                ["Heart attack, heart bypass or stent", "Stroke"].includes(ans.text)
            )
            .map((ans: any) => {
              return {
                disclosureName: ans.text,
                disclosureDiscription: "",
              };
            });

          this.pushSynopsisItems(items);
        }
      }

      if (!section?.sections) {
        continue;
      }

      const subSections: Array<any> = Object.values(section.sections);
      if (subSections && Object.keys(subSections).length > 0) {
        this.pushExceptionalMajorQuestion(subSections, exceptionalExternalRefId);
      }
    }
  }

  async generateSynopsisItemsByCondition(conditionQuestion: any) {
    const conditionsResponse: any = await InsuranceClient.getConditions(
      conditionQuestion.externalReferenceId
    );
    if (conditionsResponse.statusCode != 200) {
      return [];
    }

    const conditions: Array<any> = conditionsResponse.body.data;

    if (!Array.isArray(conditions) || conditions.length <= 0) {
      return [];
    }

    let value = conditionQuestion.value;

    if (value === null || typeof value === "undefined") {
      return [];
    }

    if (!Array.isArray(value)) {
      value = [value];
    }

    for (const v of value) {
      const condition = conditions.find((cond) => cond.id == v || cond.id == parseInt(v));
      if (!condition) {
        return null;
      }

      this.pushSynopsisItem({
        disclosureName: condition.title,
        disclosureDescription: condition.description,
      });
    }

    return this.synopsisList;
  }

  async getSynopsisList() {
    const sectionsToScan = ["Medical_Medical_history", "Medical_Condition"];

    for (const sectionToScan of sectionsToScan) {
      const targetSection = this.findSectionInQuestionnaireData(
        this.questionnaireData,
        sectionToScan
      );

      if (!targetSection) {
        continue;
      }

      await this.pushConditionQuestions(targetSection);
    }

    this.pushExceptionalMajorQuestion(this.questionnaireData, "Major_conditions");
    this.pushExceptionalYesOrNoQuestions(this.questionnaireData, [
      "Neck_back",
      "Anxiety_depression",
    ]);

    return this.synopsisList;
  }
}
