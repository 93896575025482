
import { defineComponent } from "vue";
export default defineComponent({
  name: "TextInput",
  props: {
    title: {type: String, default: ""},
    placeholder: {type: String, default: ""},
    value: {type: String, default: ""},
    description: {type: String, default: ""},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    inputClass: {type: String, default: ""}
  },
  emits: ["update:value"],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newVal:string) {
        this.$emit("update:value", newVal);
      },
    },
  },
});
