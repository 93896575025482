import { defineStore } from "pinia";
import { dataGeneratorConfigs } from "@/data-generator/DataGeneratorConfigs";

export const routerStore = defineStore("router", {
  actions: {
    getPrefixAndTabName(path: string) {
      const boosterPageUrlPrefix = dataGeneratorConfigs.boosterPageUrlPrefix;
      const pureUrl = path.replace(`/${boosterPageUrlPrefix}`, "");
      const parts = pureUrl.split("/").filter((u) => u && u.length > 0);
      if (!parts || parts.length == 0) {
        return null;
      }

      const result = {
        prefix: parts[0],
        tab: null as string | null,
      };

      if (parts.length > 1) {
        result.tab = parts[1];
      }

      return result;
    },
    getTopLevelSectionsByPath(path: string) {
      const prefixAndTabName = this.getPrefixAndTabName(path);

      if (!prefixAndTabName) {
        return null;
      }

      return dataGeneratorConfigs.topLevelSections.find(
        (sect) => sect.prefix == prefixAndTabName.prefix
      );
    },
    getFirstStaticPage() {
      const staticPages = dataGeneratorConfigs.topLevelSections
        .filter((sect) => sect.static)
        .sort((a, b) => a.sequence - b.sequence);

      if (!staticPages || staticPages.length == 0) {
        return null;
      }

      return staticPages[0];
    },
    getTab(offset: number, topLevelSection: any, path: string) {
      if (!Array.isArray(topLevelSection.tab) || topLevelSection.tab.length <= 1) {
        return null;
      }

      const prefixAndTabName = this.getPrefixAndTabName(path);
      if (!prefixAndTabName || !prefixAndTabName.tab || prefixAndTabName.tab.length <= 0) {
        return null;
      }

      const currentTabOffset = topLevelSection.tab.indexOf(prefixAndTabName.tab);
      const targetOffset = currentTabOffset + offset;
      if (currentTabOffset < 0 || targetOffset >= topLevelSection.tab.length || targetOffset < 0) {
        return null;
      }

      return topLevelSection.tab[targetOffset];
    },
    getStaticSectionAndTab(offset: number, path: string) {
      const section = this.getTopLevelSectionsByPath(path);
      if (!section) {
        return null;
      }

      const tab = this.getTab(offset, section, path);

      if (tab) {
        return {
          section,
          tab,
        };
      }

      const nextSection = dataGeneratorConfigs.topLevelSections.find(
        (sect) => sect.sequence == section?.sequence + offset
      );
      return {
        section: nextSection,
        tab: null,
      };
    },
    generatePageLinkByTopLevelSections(
      section: any,
      queryParameters: Record<string, any>,
      tab: string = ""
    ) {
      const queryParamsMapped = Object.entries(queryParameters).map(
        ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
      const queryString = "?" + queryParamsMapped.join("&");

      if (section.static) {
        if (tab && tab.length > 0) {
          return `/${dataGeneratorConfigs.boosterPageUrlPrefix}/${section.prefix}/${tab}/${queryString}`;
        }

        return `/${dataGeneratorConfigs.boosterPageUrlPrefix}/${section.prefix}${queryString}`;
      }

      return `/${section.prefix}${queryString}`;
    },
    getPage(offset: number, path: string, queryParameters: Record<string, any>) {
      const page = this.getStaticSectionAndTab(offset, path);
      if (!page || !page.section) {
        return null;
      }

      return (
        this.generatePageLinkByTopLevelSections(page.section, queryParameters, page.tab) ?? null
      );
    },
  },
});
