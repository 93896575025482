<template>
  <div
    class="quirc-general-tab position-relative"
    :class="{
      'pt-4':
        isSmallScreen &&
        !shouldAgreementCardShow &&
        !isMedicalAgreementQuestionOnTab('Your_medical_history') &&
        !isSummaryPage,
    }">
    <LoadingSpinnerWithBackground v-if="loading" />
    <div v-if="shouldAgreementCardShow" class="mb-3" :class="{ 'row ms-0 pt-1': !isSmallScreen }">
      <div class="col-lg-12 col-md-12 col-sm-12 px-0">
        <AgreementCard
          status="You agreed to let us know if you have ever been advised that you have, or have experienced, any conditions."
          :small-screen="isSmallScreen" />
      </div>
    </div>
    <div v-if="!isSummaryPage" :class="{ 'mb-3': title && title.length > 0, row: !isSmallScreen }">
      <div class="col-12 item-secondary-title">{{ title }}</div>
    </div>
    <div :class="{ row: !isSmallScreen }">
      <div
        :class="{
          'px-3': isSmallScreen,
          'p-1': !isSmallScreen,
          'col-12': !shouldSynopsisShow,
          'col-lg-9 col-md-9 col-sm-12 col-xs-12': shouldSynopsisShow,
        }">
        <!-- Summary Overview -->
        <div v-if="isSummaryPage" class="row mb-3" :class="{ 'ms-2 me-2': !isSmallScreen }">
          <div class="col-12">
            <SummaryOverview :summary="summary" :small-screen="isSmallScreen" />
          </div>
        </div>
        <div
          v-for="question in questionVMs"
          :key="question.key"
          :class="{ 'row ms-0 me-0': !isSmallScreen }">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <AgreementCard
              v-if="question.key == 'Your_medical_history'"
              v-model:value="question.value"
              :content="question.text"
              :status="''"
              :option="question.options[0]"
              :is-disabled="question.disable"
              :validation-error="readError(question.key)"
              :small-screen="isSmallScreen" />
          </div>
        </div>
        <div
          v-for="question in questionVMs"
          :key="question.key"
          class="mb-3 me-2"
          :class="{ 'row ms-2': !isSmallScreen }">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <BMICalculationItemHeight
              v-if="question.key == 'Height'"
              v-model:value="question.value"
              :title="question.text"
              :prompt="question.prompt"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable"
              :small-screen="isSmallScreen" />
            <BMICalculationItemWeight
              v-else-if="question.key == 'Weight_kg'"
              v-model:value="question.value"
              :title="question.text"
              :prompt="question.prompt"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable"
              :small-screen="isSmallScreen" />
            <div v-else-if="question.key == 'Your_medical_history'"></div>
            <div v-else-if="question.key == 'Additional_comments'"></div>
            <TextInputSmall
              v-else-if="
                question.answerType == 'SingleEntryAnswerProvider' &&
                question.dataType == 'String'
              "
              v-model:value="question.value"
              :title="question.text"
              :description="question.prompt"
              :input-class="'row'"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable" />
            <TextInput
              v-else-if="
                question.answerType == 'SingleEntryAnswerProvider' &&
                question.dataType == 'Long'
              "
              v-model:value="question.value"
              :title="question.text"
              :description="question.prompt"
              :input-class="'row'"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable" />
            <NumberInput
              v-else-if="
                (question.answerType == 'SingleEntryAnswerProvider' ||
                  question.answerType == 'CalculatedAnswerProvider') &&
                question.dataType == 'Number'
              "
              v-model:value="question.value"
              :title="question.text"
              :description="question.prompt"
              :icon="question.additionalInfo?.icon"
              :input-class="isSmallScreen ? '' : 'row'"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable" />
            <SearchDropdown
              v-else-if="
                question.answerType == 'SelectionAnswerProvider' ||
                question.answerType == 'MultipleSelectionAnswerProvider'
              "
              v-model:value="question.value"
              :title="question.text"
              :description="question.prompt"
              :placeholder="'Search and select'"
              :multiselect="question.allowMultiAnswers"
              :options="question.conditions"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable"
              :none-text="isOccupation(question) ? 'Other occupation not listed' : ''"
              :small-screen="isSmallScreen" />
            <MultipleChoiceQuestionWithNoChoice
              v-else-if="question.allowMultiAnswers"
              :id="question.key"
              v-model:value="question.value"
              :title="question.text"
              :description="question.prompt"
              :options="question.options"
              :overrides="question.overrideAnswers"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable"
              :small-screen="isSmallScreen" />
            <SingleChoiceQuestionItem
              v-else-if="!question.allowMultiAnswers"
              v-model:value="question.value"
              :title="question.text"
              :description="question.prompt"
              :options="question.options"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable"
              :small-screen="isSmallScreen" />
          </div>
        </div>
      </div>
      <div
        v-if="shouldSynopsisShow && !isSmallScreen"
        class="col-lg-3 col-md-3 col-sm-12 col-xs-12"
        :class="synopsisClass">
        <SynopsisCard title="Medical synopsis" :items="sectionDisclosures" />
      </div>
      <div v-if="isSummaryPage" :class="{ 'px-0': isSmallScreen }">
        <div style="margin-top: -2rem"></div>
        <SummaryConsents />
        <div v-for="question in questionVMs" :key="question.key">
          <div
            v-if="
              question.key == 'Additional_comments' && policyInfo.policy.policyType == '0'
            ">
            <hr class="hr" />
            <AdditionalComments />
            <TextInput
              v-model:value="question.value"
              :validation-error="readError(question.key)"
              :is-disabled="question.disable" />
          </div>
        </div>
      </div>
    </div>
    <hr
      class="hr medium-bottom-spacing"
      :class="{ 'd-none': screenWidth === ScreenWidth.Mobile }" />
    <div v-if="errorMsg && errorMsg.length > 0" class="row">
      <div class="col-12 bg-info text-white p-2 mb-2 rounded">
        <span>{{ errorMsg }}</span>
      </div>
    </div>
    <div class="row overflow-x-hidden">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 center-btn">
        <router-link
          v-if="previousPath"
          :class="{
            'btn-back-disable': loading,
            'd-none': screenWidth === ScreenWidth.Mobile,
          }"
          class="btn btn-booster-secondary"
          :to="previousPath"
          >Back</router-link
        >
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-end center-btn">
        <a
          class="btn btn-booster-primary float-end"
          :class="{ 'btn-next-disable': loading, 'me-2': isSmallScreen }"
          @click="goToNextPage()">
          <span>Next</span>
        </a>
      </div>
      <div
        v-if="shouldSynopsisShow && isSmallScreen"
        class="col-sm-12 col-xs-12"
        :class="isSmallScreen ? 'p-1' : 'ps-4 border-start'">
        <div class="ms-2 me-2">
          <hr class="hr medium-bottom-spacing" />
        </div>
        <SynopsisCard title="Medical Synopsis" :items="sectionDisclosures" />
      </div>
    </div>
  </div>
</template>

<script>
import InsuranceClient from "@/rest-client/InsuranceClient";
import MultipleChoiceQuestionWithNoChoice from "@/components/multiple-choice-question-with-no-choice/MultipleChoiceQuestionWithNoChoice.vue";
import NumberInput from "@/components/number-input/NumberInput.vue";
import SearchDropdown from "@/components/search-dropdown/SearchDropdown.vue";
import SingleChoiceQuestionItem from "@/components/single-choice-question-Item/SingleChoiceQuestionItem.vue";
import BMICalculationItemHeight from "@/components/bmi-calculation-item/BMICalculationItemHeight.vue";
import BMICalculationItemWeight from "@/components/bmi-calculation-item/BMICalculationItemWeight.vue";
import SynopsisCard from "@/components/synopsis-card/SynopsisCard.vue";
import AdditionalComments from "@/components/summary-overview/AdditionalComments.vue";
import SummaryOverview from "@/components/summary-overview/SummaryOverview.vue";
import SummaryConsents from "@/components/summary-overview/SummaryConsents.vue";
import AgreementCard from "@/components/agreement-card/AgreementCard.vue";
import TextInput from "@/components/text-input/TextInput.vue";
import TextInputSmall from "@/components/text-input/TextInputSmall.vue";
import LoadingSpinnerWithBackground from "@/components/loading-spinner/LoadingSpinnerWithBackground.vue";
import { routerStore } from "@/stores/RouterStore";
import { popupStore } from "@/stores/PopupStore";
import { mapActions, mapState } from "pinia";
import { ScreenWidth } from "@/enums/ScreenWidth.enum";
import { insuranceStore } from "@/stores/InsuranceStore";

export default {
  name: "QuricGeneralTab",
  components: {
    MultipleChoiceQuestionWithNoChoice,
    NumberInput,
    SingleChoiceQuestionItem,
    SearchDropdown,
    BMICalculationItemHeight,
    BMICalculationItemWeight,
    SynopsisCard,
    SummaryOverview,
    AgreementCard,
    TextInput,
    TextInputSmall,
    SummaryConsents,
    LoadingSpinnerWithBackground,
    AdditionalComments,
  },
  props: {
    questions: { type: Array, required: true },
    nextPath: { type: [String, null], required: true },
    previousPath: { type: [String, null], required: true },
    title: { type: String, default: "" },
    sectionDisclosures: { type: Array, required: true },
    summary: { type: Object, required: true },
    reloadQuestionsHandler: { type: Function, required: true },
    screenWidth: { type: String, required: true },
  },
  data() {
    return {
      synopsisClass: "",
      loading: false,
      errorMsg: null,
      errors: {},
      questionVMs: [],
      alreadySubmitted: false,
      ScreenWidth: ScreenWidth,
    };
  },
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
    shouldAgreementCardShow() {
      let path = this.$route.path;
      return (
        (path?.includes("Medical_history") ||
          path?.includes("Medical_About_your_family") ||
          path?.includes("Medical_Condition")) &&
        !this.isMedicalAgreementQuestionOnTab("Your_medical_history")
      );
    },
    shouldSynopsisShow() {
      let path = this.$route.path;
      // Don't show synopsis on first medical page.
      // The !path.includes accounts for when you use the navigation to go to this page. In that case, the subroute isn't defined.
      if (
        path.includes("/DNDT_Medical_history_1") ||
        (!path.includes("/Medical_Medical_history/") &&
          path.includes("/Medical_Medical_history"))
      ) {
        return false;
      }
      return (
        ((path?.includes("/Medical_Medical_history") ||
          path?.includes("/Medical_About_your_family") ||
          path?.includes("/Medical_Condition")) ??
          false) &&
        this.sectionDisclosures &&
        this.sectionDisclosures.length > 0
      );
    },
    isSummaryPage() {
      let path = this.$route.path;
      return path?.includes("/Medical_Summary") ?? false;
    },
    isSmallScreen() {
      return [ScreenWidth.Mobile, ScreenWidth.Small].includes(this.screenWidth);
    },
  },
  created() {
    this.questionVMs = this.questions.map((question) => {
      if (question.value) {
        question.disable = true;
      }
      return question;
    });

    this.alreadySubmitted = this.questionVMs.every((question) => question.value);

    // A second map so that we don't disrupt the alreadySubmitted logic
    this.questionVMs = this.questionVMs.map((questionVM) => {
      if (!questionVM.value) {
        // Pre-select Gender and Smoker Status which would have been entered in Member Portal
        if (questionVM.key.toLowerCase() === "sex_at_birth") {
          questionVM.value = this.policyInfo.insuredPerson.gender;
        }

        if (questionVM.key.toLowerCase() === "your_smoker_status") {
          questionVM.value = this.policyInfo.insuredPerson.smokerStatus;
        }
      }

      return questionVM;
    });
  },
  methods: {
    ...mapActions(routerStore, [
      "generatePageLinkByTopLevelSections",
      "getFirstStaticPage",
    ]),
    ...mapActions(popupStore, ["showErrorMsg"]),
    getOptionalQuestionsRefIds() {
      let optionalQuestions = this.questionVMs.filter(
        (question) => !question.answerRequired
      );
      return optionalQuestions?.map((question) => question.key);
    },
    validateAnswers(answers) {
      // clear old errors
      this.errors = {};

      let valid = true;
      let optionalQuestions = this.getOptionalQuestionsRefIds();
      for (let answer of answers) {
        let refId = answer.externalReferenceId;
        let value = answer.value;
        let multipleAnswers = answer.multipleAnswers;

        if (optionalQuestions?.includes(refId)) {
          continue;
        }

        // Check general cases
        if (!value && !multipleAnswers) {
          this.errors[refId] = "Answer required";
          valid = false;
        }

        if (typeof value == "string" && value.length <= 0) {
          this.errors[refId] = "Answer required";
          valid = false;
        }

        if (typeof value == "string" && value.length > 255) {
          this.errors[refId] = "Answer too long. Max length is 255 characters";
          valid = false;
        }

        // Modify error message if it's agreement card
        if (
          this.errors["Your_medical_history"] &&
          this.errors["Your_medical_history"].length > 0
        ) {
          this.errors["Your_medical_history"] = "Acceptance required";
        }

        // Check special cases
        if (refId == "Height") {
          if (isNaN(value) || value > 2.5 || value < 0) {
            this.errors[refId] = "Invalid value";
            valid = false;
          }
        }

        if (refId == "Weight_kg") {
          if (isNaN(value) || value > 1000 || value < 0) {
            this.errors[refId] = "Invalid value";
            valid = false;
          }
        }
      }

      return valid;
    },
    async goToNextPage() {
      if (this.loading) {
        return;
      }

      if (this.alreadySubmitted) {
        this.$router.push(this.nextPath ?? this.getNextBoosterPath());
        return;
      }

      this.loading = true;

      let answers = this.mapAnswers();

      if (!this.validateAnswers(answers)) {
        this.loading = false;
        return;
      }

      // remove the empty optional comment
      answers = answers.filter((answer) => {
        if (
          answer.externalReferenceId == "Additional_comments" &&
          ((answer.value && answer.value.length <= 0) || !answer.value)
        ) {
          return false;
        }
        return true;
      });

      if (answers && answers.length > 0) {
        let response = await InsuranceClient.createOrUpdateClient(answers);
        if (response.statusCode != 200) {
          this.errorMsg = "Error happened while submitting data.";
          if (response.statusCode == 400) {
            this.errors = response.body?.errors;
            this.errorMsg += " Please check your answers.";
          }
          this.loading = false;
          return;
        }

        // Patch the InsuredPerson if we are updating the Smoker Status or Gender fields
        let referenceIdsFilter = ["sex_at_birth", "your_smoker_status", "your_medical_practice_name"];
        let genderOrSmokerStatusAnswer = answers.find((answer) =>
          referenceIdsFilter.includes(answer.externalReferenceId.toLowerCase())
        );
        if (genderOrSmokerStatusAnswer) {
          let patchUpdateResponse = await InsuranceClient.updateInsuranceInsuredPerson(
            this.policyInfo.insuredPerson.id,
            this.toJsonPatchDocument(
              this.policyInfo.insuredPerson,
              genderOrSmokerStatusAnswer
            )
          );
          if (patchUpdateResponse.statusCode !== 200) {
            this.errorMsg += " Error happened while updating insured person.";
            if (response.statusCode === 400) {
              this.errors = patchUpdateResponse.body?.errors; // this overwrites any errors of previous http request
              this.errorMsg += " Please check submitted data.";
            }
            this.loading = false;
            return;
          }
        }
      }

      if (!this.nextPath) {
        const isSubmitted = await this.submit();
        this.loading = false;

        if (isSubmitted) {
          this.$router.push(this.getNextBoosterPath());
        } else {
          this.showErrorMsg(
            "Submission failed. Please check your answers and try again."
          );
        }
        return;
      }

      // Reload data for reflect questions
      let initialQuestionNumber = this.questionVMs.length;
      let reloadedQuestionVMs = await this.reloadQuestionsHandler(true);

      // If there is no reflective questions show up, go next
      if (reloadedQuestionVMs?.questions.length == initialQuestionNumber) {
        this.$router.push(this.nextPath);
      }

      this.loading = false;
    },
    mapAnswers() {
      return this.questionVMs.map((question) => {
        let answer = {
          externalReferenceId: question.key,
        };

        if (question.allowMultiAnswers && Array.isArray(question.value)) {
          answer.multipleAnswers = question.value.map((value) => {
            return {
              value,
            };
          });
        } else {
          answer.value = question.value;
        }

        return answer;
      });
    },
    isMedicalAgreementQuestionOnTab(agreementQuestionId) {
      return (
        typeof this.questions?.find((question) => question.key == agreementQuestionId) ===
        "object"
      );
    },
    isOccupation(question) {
      return question.key == "Occupation" || question.key == "Other_occupation";
    },
    async submit() {
      let response = await InsuranceClient.submitQuestionnaire();
      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.log(response.body);
      }
      if (response.statusCode != 200) {
        return false;
      }
      return true;
    },
    readError(key) {
      if (!this.errors) {
        return undefined;
      }

      return this.errors[key];
    },
    getNextBoosterPath() {
      const firstBoosterPage = this.getFirstStaticPage();
      if (!firstBoosterPage) {
        return "";
      }

      return this.generatePageLinkByTopLevelSections(firstBoosterPage, this.$route.query);
    },
    toJsonPatchDocument(insuredPerson, answer) {
      const refIdMapping = {
        sex_at_birth: "gender",
        your_smoker_status: "smokerStatus",
        your_medical_practice_name: "medicalPractitioner"
      };

      // Map gender option 'Intersex' to 'Male' for database purposes
      const answerValue = answer.value === "Intersex" ? "Male" : answer.value;

      // IsUpdatingGenderSmokerStatusOnly flag is for standalone smokerstatus and gender update
      // to avoid validation error
      return [
        {
          op: "replace",
          path: `/${refIdMapping[answer.externalReferenceId.toLowerCase()]}`,
          value: answerValue,
        },
        {
          op: "replace",
          path: "/isUpdatingGenderSmokerStatusOnly",
          value: true,
        },
      ];
    },
  },
};
</script>
