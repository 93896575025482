import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "item-secondary-title mb-2"
}
const _hoisted_3 = {
  key: 2,
  class: "hr"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummarySection = _resolveComponent("SummarySection", true)!
  const _component_SummaryQuestion = _resolveComponent("SummaryQuestion")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subSections, (subSection, index) => {
    return (_openBlock(), _createElementBlock("div", { key: index }, [
      (subSection.externalReferenceId != 'Medical_Summary')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.currentLayer == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(subSection.title), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_SummarySection, {
              "sub-sections": subSection.sections,
              layer: _ctx.currentLayer
            }, null, 8, ["sub-sections", "layer"]),
            (subSection.questions)
              ? (_openBlock(), _createBlock(_component_SummaryQuestion, {
                  key: 1,
                  questions: subSection.questions
                }, null, 8, ["questions"]))
              : _createCommentVNode("", true),
            (_ctx.currentLayer == 0)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}