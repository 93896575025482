
import { defineComponent, PropType } from "vue";
import { QuestionnaireAnswerSubSection } from "@/interfaces/QuestionnaireAnswer";
import CollapsibleCard from "@/components/collapsible-card/CollapsibleCard.vue";

export default defineComponent({
  name: "QuestionnaireAnswerSection",
  components: {
    CollapsibleCard,
  },
  props: {
    title: {type: String, required: true},
    subSections: {type: Array as PropType<QuestionnaireAnswerSubSection[]>, default: () => []},
    isMobileScreen: {type: Boolean, default: false}
  },
  methods: {
    showSectionDivider(sectionTitle: string, sectionLength: number, sectionIndex: number): boolean {
      // Show the section divider if current section has a title and is not the last section
      return sectionTitle !== '' && (sectionIndex + 1) < sectionLength;
    }
  }
});
