import { defineStore } from "pinia";
import QuestionnaireDataGenerator from "@/data-generator/QuestionnaireDataGenerator";
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";

export const paymentStore = defineStore("payment", {
  actions: {
    generatePaymentNavData(policy: InsurancePolicy) {
      const section = {
        name: "Payment",
        prefix: "Payment",
        // sequence should be 6
        sequence: 5,
      };

      const path =
        "/" + section.prefix + this.getUrlParamString(QuestionnaireDataGenerator.queryParams);

      return {
        text: section.name,
        link: path,
        isChecked: this.checkInfoCompleted(policy),
      };
    },
    getUrlParamString(params: any) {
      if (!params) {
        return "";
      }
      //TODO: Remove anys once QuestionnaireDataGenerator has been converted to typescript
      // Or find a different way to get the queryParams that doesn't use QuestionnaireDataGenerator
      let entries = Object.entries(params);
      entries = entries.map(
        ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as any)}`
      ) as any;
      return "?" + entries.join("&");
    },
    checkInfoCompleted(policy: InsurancePolicy) {
      if (policy == undefined) {
        return false;
      }

      const payment = policy?.paymentConfigurations[0];

      if (payment == undefined) {
        return false;
      }

      if (payment.method == "Direct debit") {
        if (payment.directDebit == null) {
          return false;
        }

        const nullValues = Object.entries(payment.directDebit).filter((entry) => {
          const key = entry[0];
          const value = entry[1];
          if (key == "policyMemberNumber") {
            return false;
          }

          return value === null;
        });

        if (nullValues.length == 0) {
          return true;
        }

        return false;
      }

      if (Object.values(payment).filter((x) => x == null).length > 1) {
        return false;
      }

      return true;
    },
  },
});
