import InsuranceClient from "@/rest-client/InsuranceClient";
import { popupStore } from "@/stores/PopupStore";
import { ProductName } from "@/enums/ProductName.enum";

export default class QuestionnaireDataGenerator {
  static questionDataCache = {};
  static sectionDataCache = {};
  static queryParams;

  questionnaireData = {};
  hiddenQuestions = {
    unanswered: [],
    answered: [],
  };
  sectionRelationshipMap = {};
  sectionConfig = {};

  popupBox = popupStore();

  constructor(config, queryParams = null) {
    this.sectionConfig = config;
    QuestionnaireDataGenerator.queryParams = queryParams;
  }

  static setQuestionDataCache(extRefId, data) {
    if (this.questionDataCache[extRefId]) {
      return;
    }

    this.questionDataCache[extRefId] = data;
  }

  static setSectionDataCache(extRefId, data) {
    if (this.sectionDataCache[extRefId]) {
      return;
    }

    this.sectionDataCache[extRefId] = data;
  }

  async getUpdatedPolicyData(applicationSubmitted) {
    let response;
    if (!applicationSubmitted) {
      response = await InsuranceClient.createOrUpdateClient();
    }

    if (applicationSubmitted || response.statusCode !== 200) {
      // TODO: check error details to reload completed questionnaire
      response = await InsuranceClient.getPolicySchedule();

      if (response.statusCode !== 200) {
        this.popupBox.showErrorMsg("Please check your network. Cannot fetch crucial data.");
        return;
      }

      // TODO: detailed mapping later if required
      return {
        answeredQuestions: response.body.questionAnswers,
        unAnsweredQuestions: [],
      };
    }

    return response.body;
  }

  async getQuestionsForSectionData(sectionExtRefId) {
    if (!QuestionnaireDataGenerator.questionDataCache[sectionExtRefId]) {
      let response = await InsuranceClient.getQuestionsForSection(sectionExtRefId);

      if (response.statusCode !== 200) {
        this.popupBox.showErrorMsg("Please check your network. Cannot fetch crucial data.");
        return;
      }

      let targetSectionQuestions = response.body.sections?.find(
        (x) => x.externalReferenceId == sectionExtRefId
      )?.questions;

      if (!targetSectionQuestions) {
        return null;
      }
      QuestionnaireDataGenerator.questionDataCache[sectionExtRefId] = targetSectionQuestions;
    }

    return QuestionnaireDataGenerator.questionDataCache[sectionExtRefId];
  }

  async getSectionDataByExtRefId(sectionExtRefId) {
    if (!QuestionnaireDataGenerator.sectionDataCache[sectionExtRefId]) {
      let response = await InsuranceClient.getSections(sectionExtRefId);

      if (response.statusCode !== 200) {
        this.popupBox.showErrorMsg("Please check your network. Cannot fetch crucial data.");
        return;
      }

      let sectionsData = response.body;
      if (sectionsData.sections.length < 0) {
        if (process.env.VUE_APP_LOG_LEVEL > 0) {
          console.error("Invalid section info");
        }
        return;
      }

      let targetSection = sectionsData.sections?.find(
        (x) => x.externalReferenceId == sectionExtRefId
      );

      if (!targetSection) {
        return null;
      }

      QuestionnaireDataGenerator.sectionDataCache[sectionExtRefId] = targetSection;
    }

    return QuestionnaireDataGenerator.sectionDataCache[sectionExtRefId];
  }

  getSectionTitle(title, extRefId) {
    if (!this.sectionConfig.hiddenSectionTitles) {
      return title;
    }

    let matchingPrefix = this.sectionConfig.hiddenSectionTitles.prefixes.find((prefix) =>
      extRefId.startsWith(prefix)
    );

    if (matchingPrefix) {
      return null;
    }

    return title;
  }

  sectionToHide(extRefId) {
    if (!this.sectionConfig.hiddenSections) {
      return false;
    }

    if (this.sectionConfig.hiddenSections.names.includes(extRefId)) {
      return true;
    }

    if (this.sectionConfig.hiddenSections.prefixes.find((prefix) => extRefId.startsWith(prefix))) {
      return true;
    }

    return false;
  }

  getFixedParentSection(extRefId) {
    if (!this.sectionConfig.topLevelSections) {
      return null;
    }

    let matchingSection = this.sectionConfig.topLevelSections.find((section) =>
      extRefId.startsWith(section.prefix)
    );

    if (matchingSection) {
      return matchingSection;
    }

    return null;
  }

  sortSections(sectionData) {
    sectionData = Object.fromEntries(
      Object.entries(sectionData).sort((a, b) => {
        return a[1].overallSequence - b[1].overallSequence;
      })
    );

    for (const i in sectionData) {
      let data = sectionData[i];
      if (data.sections && Object.keys(data.sections) && Object.keys(data.sections).length > 0) {
        data.sections = this.sortSections(data.sections);
      }
    }

    return sectionData;
  }

  getUrlParamString(params) {
    if (!params) {
      return "";
    }

    let entries = Object.entries(params);
    entries = entries.map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    return "?" + entries.join("&");
  }

  async setupSections(section) {
    let sectionStack = [section.externalReferenceId];
    while (section.parentExternalReferenceId) {
      sectionStack.push(section.parentExternalReferenceId);
      section = await this.getSectionDataByExtRefId(section.parentExternalReferenceId);
    }

    let levelToAdd = this.questionnaireData;
    let currentPath = "";
    let overallSequence = 0;
    let layerIndex = 0;

    // Check if there is any fixed parent section
    if (sectionStack.length > 0) {
      let topFixedSection = this.getFixedParentSection(sectionStack[sectionStack.length - 1]);
      if (topFixedSection) {
        currentPath += "/" + topFixedSection.prefix;
        if (!levelToAdd[topFixedSection.prefix]) {
          levelToAdd[topFixedSection.prefix] = {
            questions: [],
            sections: {},
            title: topFixedSection.name,
            overallSequence: topFixedSection.sequence,
            path: currentPath + this.getUrlParamString(QuestionnaireDataGenerator.queryParams),
          };
        }

        overallSequence = levelToAdd[topFixedSection.prefix].overallSequence;
        levelToAdd = levelToAdd[topFixedSection.prefix].sections;
        layerIndex++;
      }
    }

    while (sectionStack.length > 0) {
      let sectionExtRefId = sectionStack.pop();
      currentPath += "/" + sectionExtRefId;
      let section = await this.getSectionDataByExtRefId(sectionExtRefId);

      if (!levelToAdd[sectionExtRefId]) {
        section.questions = [];
        section.sections = {};
        section.path = currentPath + this.getUrlParamString(QuestionnaireDataGenerator.queryParams);
        section.title = this.getSectionTitle(section.title, sectionExtRefId);
        section.overallSequence =
          overallSequence +
          (section.sectionSequence ?? section.parentSectionSequence) / 100 ** layerIndex;
        levelToAdd[sectionExtRefId] = section;
      }

      overallSequence = levelToAdd[sectionExtRefId].overallSequence;
      levelToAdd = levelToAdd[sectionExtRefId].sections;
      layerIndex++;
    }
  }

  async findTargetSectionInCache(section) {
    let sectionStack = [section.externalReferenceId];
    while (section.parentExternalReferenceId) {
      sectionStack.push(section.parentExternalReferenceId);
      section = await this.getSectionDataByExtRefId(section.parentExternalReferenceId);
    }

    // Check if there is any fixed parent section
    if (sectionStack.length > 0) {
      let topFixedSection = this.getFixedParentSection(sectionStack[sectionStack.length - 1]);
      if (topFixedSection) {
        sectionStack.push(topFixedSection.prefix);
      }
    }

    let targetSection = this.questionnaireData;
    let sectionToReturn = null;
    while (sectionStack.length > 0) {
      let sectionExtRefId = sectionStack.pop();

      sectionToReturn = targetSection[sectionExtRefId];
      targetSection = sectionToReturn.sections;
    }

    return sectionToReturn;
  }

  addSectionToRelationShipMap(section) {
    this.sectionRelationshipMap[section.externalReferenceId] = section;

    // Sort by sequence number
    this.sectionRelationshipMap = Object.fromEntries(
      Object.entries(this.sectionRelationshipMap).sort((a, b) => {
        return a[1].overallSequence - b[1].overallSequence;
      })
    );
  }

  getSectionRelationshipMap() {
    return this.sectionRelationshipMap;
  }

  checkSectionCompleted(section) {
    if (section && section.questions && section.questions.length > 0) {
      return (
        section.questions.filter((question) => {
          return (
            (question.value !== null && typeof question.value != "undefined") ||
            (question.multipleAnswers !== null && typeof question.multipleAnswers != "undefined") ||
            question.answerRequired != true
          );
        }).length == section.questions.length
      );
    }

    return false;
  }

  async processQuestionDetails(section, question, isAnswered, isHidden) {
    let sectionQuestions = await this.getQuestionsForSectionData(question.sectionExtRefId);

    if (sectionQuestions === null) {
      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.warn(
          `The question ${section.externalReferenceId}:${question.externalReferenceId} doesn't have valid section questions.`
        );
      }
      return;
    }

    let questionDetails = sectionQuestions.find(
      (q) => q.externalReferenceId == question.externalReferenceId
    );

    if (!questionDetails) {
      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.warn(
          `The question ${question.externalReferenceId} cannot be found in section ${question.sectionExtRefId}.`
        );
      }
      return;
    }

    questionDetails.isAnswered = isAnswered;

    if (isAnswered) {
      questionDetails.value =
        question.value ?? question.multipleAnswers?.map((multiAnswer) => multiAnswer.value);
    }

    if (isHidden) {
      isAnswered
        ? this.hiddenQuestions.answered.push(questionDetails)
        : this.hiddenQuestions.unanswered.push(questionDetails);
    } else {
      let sectionInQuestionnaireCache = await this.findTargetSectionInCache(section);
      sectionInQuestionnaireCache.questions.push(questionDetails);
      sectionInQuestionnaireCache.isCompleted = this.checkSectionCompleted(
        sectionInQuestionnaireCache
      );
    }
  }

  async processQuestionSection(question, isHidden) {
    let section = await this.getSectionDataByExtRefId(question.sectionExtRefId);

    if (section === null) {
      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.warn(`The question ${question.externalReferenceId} doesn't have a valid section.`);
      }
      return;
    }

    if (!isHidden) {
      await this.setupSections(section);

      // Collect the end leaves of the visible section tree to make navigation (next/previous button) easier.
      this.addSectionToRelationShipMap(section);
    }

    return section;
  }

  getSumInsured(answeredQuestions) {
    let products = [
      {
        displayName: ProductName.SSLI.toString(),
        policyType: "1",
        key: "Sum_insured_for_life_cover",
        value: 0,
      },
      {
        displayName: ProductName.CLI.toString(),
        policyType: "0",
        key: "Sum_insured_for_life_cover",
        value: 0,
      },
      {
        displayName: ProductName.TCI.toString(),
        policyType: "0",
        key: "Sum_insured_for_trauma_and_critical_illness",
        value: 0,
      },
      {
        displayName: ProductName.TPD.toString(),
        policyType: "0",
        key: "Sum_insured_for_TPD",
        value: 0,
      },
    ];

    answeredQuestions.filter((item) => {
      products.forEach((product) => {
        if (item.externalReferenceId === product.key && Number(item.value) > 0) {
          product.value = Number(item.value);
        }
      });
    });

    return products;
  }

  async processQuestions(questionsInfo) {
    let { questions, processedQuestionNumber, questionLoadPromises, isAnswered } = questionsInfo;

    for (const i in questions) {
      let question = questions[i];

      if (this.sectionConfig?.hiddenQuestions?.prefixes) {
        let match = this.sectionConfig?.hiddenQuestions?.prefixes.find((prefix) =>
          question.externalReferenceId.startsWith(prefix)
        );

        if (match) {
          continue;
        }
      }

      let isHidden = this.sectionToHide(question.sectionExtRefId);
      let section = await this.processQuestionSection(question, isHidden);

      if (!section) {
        continue;
      }

      let loadQuestionDetailsPromise = this.processQuestionDetails(
        section,
        question,
        isAnswered,
        isHidden
      );

      if (!questionLoadPromises[question.sectionExtRefId]) {
        questionLoadPromises[question.sectionExtRefId] = [];
      }

      questionLoadPromises[question.sectionExtRefId].push(loadQuestionDetailsPromise);

      processedQuestionNumber++;
    }

    questionsInfo.processedQuestionNumber = processedQuestionNumber;
    questionsInfo.questionLoadPromises = questionLoadPromises;
  }

  async generate(applicationSubmitted) {
    let policyData = await this.getUpdatedPolicyData(applicationSubmitted);

    if (!policyData.unAnsweredQuestions) {
      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.warn("Invalid policy data.");
      }
      return null;
    }

    // Set the inital progress values
    let unansweredQuestions = policyData.unAnsweredQuestions;
    let answeredQuestions = policyData.answeredQuestions;
    let totalQuestionNumber = unansweredQuestions.length + answeredQuestions.length;
    let processedQuestionNumber = 0;

    let questionsInfo = {
      processedQuestionNumber,
      totalQuestionNumber,
      questionLoadPromises: {},
      isAnswered: false,
    };
    // Generate unanswered questions
    questionsInfo.questions = unansweredQuestions;
    await this.processQuestions(questionsInfo);

    // Generate answered questions
    questionsInfo.questions = answeredQuestions;
    questionsInfo.isAnswered = true;
    await this.processQuestions(questionsInfo);

    this.questionnaireData = this.sortSections(this.questionnaireData);

    let sumInsured = this.getSumInsured(answeredQuestions);

    return {
      questionnaireData: this.questionnaireData,
      questionLoadPromises: questionsInfo.questionLoadPromises,
      submittedSumInsured: sumInsured,
    };
  }
}
