import { ConsentAnswer } from "@/interfaces/ConsentAnswer";
import { ConsentQuestion } from "@/interfaces/ConsentQuestion";
import InsuranceClient from "@/rest-client/InsuranceClient";
import { defineStore } from "pinia";
import { InsurancePolicy } from "@/interfaces/InsurancePolicy";
import { Router, RouteLocationNormalizedLoaded, LocationQuery } from "vue-router";
import RequestBuilder from "@/rest-client/RequestBuilder";
import { popupStore } from "./PopupStore";
import { PolicyBenefit } from "@/interfaces/payment/PolicyBenefit";
import { PolicyBenefitStatusRequest } from "@/interfaces/requests/PolicyBenefitStatusRequest";
import { BenefitStatus } from "@/enums/BenefitStatus.enum";

export const confirmationStore = defineStore("Confirmation", {
  state: () => {
    return {
      confirmationItems: [
        {
          step: 1,
          text: "InsuranceWebUAPCDOBVerification",
          routePath: "DOBVerification",
          isChecked: false,
        },
        {
          step: 2,
          text: "InsuranceWebUAPCDirectDebit",
          routePath: "DDConfirmation",
          isChecked: false,
        },
        {
          step: 3,
          text: "InsuranceWebUAPCAcceptanceCover",
          routePath: "CoverAcceptance",
          isChecked: false,
        },
        {
          step: 4,
          text: "InsuranceWebUAPCPaymentConfirmation",
          routePath: "PaymentConfirmation",
          isChecked: false,
        },
      ],
      policyInfo: {
        policy: {} as InsurancePolicy,
        insuredPerson: {} as any,
        policyRefId: "DefaultPolicyRefId",
        bankHolderAcceptance: false,
        seperateBankHolder: false,
      },
    };
  },
  actions: {
    setConfirmationItemAsChecked(cnfItem: string) {
      const pageIndex = this.confirmationItems.findIndex((item) => item.text === cnfItem);
      this.confirmationItems[pageIndex].isChecked = true;
    },
    checkConfirmationPageStatus(pageName: string) {
      const pageIndex = this.confirmationItems.findIndex((item) => item.text === pageName);
      return this.confirmationItems[pageIndex].isChecked;
    },
    areAllPreviousPagesNotCompleted(currentPageName: string): boolean {
      const currentPage = this.confirmationItems.find((page) => page.text === currentPageName);

      if (!currentPage) {
        // currentPageName was not found in the item list for some reason, assume further evaluation required
        return false;
      }

      return (
        this.confirmationItems
          // Are all pages before this page to be not checked
          .filter((page) => page.step < currentPage.step)
          .every((page) => !page.isChecked)
      );
    },
    areAllConsentQuestionsConfirmedForPage(
      pageName: string,
      consentQuestions?: ConsentQuestion[]
    ): boolean {
      // If page contains consent questions, check to see if they have all been confirmed
      const pagesWithConsentQuestions = [
        "InsuranceWebUAPCDirectDebit",
        "InsuranceWebUAPCAcceptanceCover",
      ];

      if (!pagesWithConsentQuestions.includes(pageName) || !consentQuestions) {
        // Note that this function replaces checkConfirmationPageStatus for pages that contain consent questions.
        // If this function is called from a page that is NOT a consent question page OR no consent questions were found,
        // then we should just check the confirmation page (as if it were the old flow)
        return this.checkConfirmationPageStatus(pageName);
      }

      const uncheckedQuestions = consentQuestions.filter(
        (element: ConsentQuestion) => JSON.parse(element.value) === false
      );

      if (uncheckedQuestions.length === 0) {
        // All confirmation questions have been checked
        return true;
      }

      // Not all confirmation questions have been checked, return false
      return false;
    },
    arePreviousPagesPartiallyCompleted(name: string) {
      const stepNumber =
        this.confirmationItems.find((item) => item.text.toLowerCase() === name.toLowerCase())
          ?.step ?? 0;
      if (stepNumber) {
        const targetSteps = this.confirmationItems.filter((item) => item.step < stepNumber);
        return targetSteps.some((item) => !item.isChecked);
      }
      return false;
    },
    getDobVerificationPageRoute(fullPath: string): string {
      const { params } = this.parseUrlPageAndParamsFromPath(fullPath);

      const dobVerificationPageName = "InsuranceWebUAPCDOBVerification";
      // Get the first page - DOB Verification
      const dobVerificationItem =
        this.confirmationItems.find((item) => item.text === dobVerificationPageName) ??
        this.confirmationItems[0];

      return `${dobVerificationItem.routePath}?${params}`;
    },
    parseUrlPageAndParamsFromPath(path: string): { page: string; params: string } {
      const parentRoute = "Confirmation";
      const emptyResult = { page: "", params: "" };

      // Parse current path to find the current page
      const pathWithoutParentRoute = path
        .split("/")
        .find(
          (substring: string) => substring && parentRoute.toLowerCase() !== substring.toLowerCase()
        );

      if (!pathWithoutParentRoute) {
        return emptyResult;
      }

      const pathFragments = pathWithoutParentRoute.split("?");

      if (pathFragments.length !== 2) {
        return emptyResult;
      }

      return { page: pathFragments[0], params: pathFragments[1] };
    },
    getNextPage(currentPath: string): string {
      const { page: currentPage, params } = this.parseUrlPageAndParamsFromPath(currentPath);

      // Verify that current page is valid
      const currentConfirmationItem = this.confirmationItems.find(
        (item) => item.routePath.toLowerCase() === currentPage.toLowerCase()
      );
      if (!currentConfirmationItem) {
        return ""; // Falsey result
      }

      // Get the next step
      // Check that we are not at the last page
      if (
        currentConfirmationItem.step ===
        Math.max(...this.confirmationItems.map((item) => item.step))
      ) {
        return "";
      }

      let nextConfirmationItem = this.confirmationItems.find(
        (item) => item.step === currentConfirmationItem.step + 1
      );

      if (
        this.policyInfo.bankHolderAcceptance &&
        nextConfirmationItem &&
        nextConfirmationItem.text == "InsuranceWebUAPCAcceptanceCover"
      ) {
        const currentStep = nextConfirmationItem.step;
        this.setConfirmationItemAsChecked(nextConfirmationItem.text);
        nextConfirmationItem = this.confirmationItems.find((item) => item.step === currentStep + 1);
      }

      if (
        this.policyInfo.seperateBankHolder &&
        !this.policyInfo.bankHolderAcceptance &&
        nextConfirmationItem &&
        nextConfirmationItem.text == "InsuranceWebUAPCDirectDebit"
      ) {
        const currentStep = nextConfirmationItem.step;
        this.setConfirmationItemAsChecked(nextConfirmationItem.text);
        nextConfirmationItem = this.confirmationItems.find((item) => item.step === currentStep + 1);
      }

      return nextConfirmationItem ? `${nextConfirmationItem.routePath}?${params}` : "";
    },
    getCancelledPage(queryParams: LocationQuery, page: string): string {
      const queryParamsMapped = Object.entries(queryParams).map(
        ([k, v]: any) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
      const queryString = "?" + queryParamsMapped.join("&");
      return `${page}${queryString}`;
    },
    async getConsentQuestions(consentPage: string) {
      const response = await InsuranceClient.getConsentQuestions(consentPage);
      return response.body.map((element: any) => {
        return {
          questionId: element.id,
          question: element.question,
          text: element.content?.replace(/{|}/g, ""),
          options: JSON.parse(element.options),
          value: JSON.parse(element.value) ?? false,
        };
      });
    },
    async getQuestionnaireAnswers() {
      const response = await InsuranceClient.getQuestionnaireAnswers(this.policyInfo.policyRefId);
      return response.body.questionAnswers;
    },
    getYourQuoteDetails() {
      return {
        title: "Your cover",
        questionnaireAnswerSubSections: [
          {
            title: "",
            sectionTitle: "",
            questionnaireAnswers: this.policyInfo.policy.policyBenefits
              .filter(
                (policyBenefit: any) => policyBenefit.benefitStatus === "WaitingForConfirmation"
              )
              .map((policyBenefit: any, index: number) => {
                return {
                  answerText: `${policyBenefit.benefit.benefitName} - $${parseFloat(
                    policyBenefit.coverAmount.toString()
                  ).toLocaleString()}`,
                  questionText: index > 0 ? "" : "Insurance product and cover amount", // Only show the question if this is the first item
                };
              }),
          },
        ],
      };
    },
    async replaceWordWithLink(
      consentPage: string,
      documentTypes: { documentType: string; documentLink: string }[]
    ) {
      const response = await InsuranceClient.getConsentQuestions(consentPage);
      return response.body.map((element: any) => {
        let dataText = element.content;
        documentTypes.forEach((data) => {
          dataText = dataText.replace(data.documentType, data.documentLink);
        });
        return {
          questionId: element.id,
          question: element.question,
          text: dataText,
          options: JSON.parse(element.options),
          value: JSON.parse(element.value) ?? false,
        };
      });
    },
    async submitConsentQuestionAnswers(consentQuestions: ConsentQuestion[]) {
      const consentAnswers: ConsentAnswer[] = [];
      consentQuestions.forEach((element) => {
        consentAnswers.push({
          consentQuestionId: element.questionId,
          value: element.value,
        });
      });
      return await InsuranceClient.submitConsentQuestionAnswers(consentAnswers);
    },
    // This is a separate endpoint which will trigger the dd letter generation
    async submitDdQuestionAnswers(consentQuestions: ConsentQuestion[]) {
      const consentAnswers: ConsentAnswer[] = [];
      consentQuestions.forEach((element) => {
        consentAnswers.push({
          consentQuestionId: element.questionId,
          value: element.value,
        });
      });
      return await InsuranceClient.submitDdQuestionAnswers(consentAnswers);
    },
    async applicationCancel(): Promise<boolean> {
      // Error handling will be handled by the individual tab

      this.policyInfo.policy.paymentConfigurations[0].directDebit = null;
      this.policyInfo.policy.paymentConfigurations[0].method = "Online banking";
      const policyDocument = [
        {
          op: "replace",
          path: "/paymentConfigurations",
          value: this.policyInfo.policy.paymentConfigurations,
        },
      ];
      const response = await InsuranceClient.updateInsurancePolicy(
        this.policyInfo.policy.id,
        policyDocument
      );

      if (response?.statusCode != 200) {
        return false;
      }

      const policyBenefitStatusRequest = {} as PolicyBenefitStatusRequest;
      policyBenefitStatusRequest.policyBenefits = this.policyInfo.policy.policyBenefits
        .filter((x) => x.benefitStatus == BenefitStatus.WaitingForConfirmation)
        .map((policyBenefit: PolicyBenefit) => {
          return {
            id: policyBenefit.id,
            benefitStatus: BenefitStatus.NotTakenUp,
          };
        });
      if (policyBenefitStatusRequest.policyBenefits.length > 0) {
        const policyBenefitStatusResponse = await InsuranceClient.updatePolicyBenefitsStatus(
          policyBenefitStatusRequest
        );

        if (policyBenefitStatusResponse.statusCode != 200) {
          return false;
        }
      }

      return true;
    },
    async parseUrlParameters(router: Router, route: RouteLocationNormalizedLoaded) {
      await router.isReady();
      const popupBox = popupStore();

      const policyInfo = route.query.policyInfo?.toString();
      if (!policyInfo) {
        popupBox.showErrorMsg("No valid policy info provided");
        throw Error("Query string (policy info) Error. Abort.");
      }

      const authResult = await InsuranceClient.getAcceptanceToken(policyInfo);

      if (authResult.statusCode != 200) {
        if (authResult.statusCode == 401) {
          popupBox.showSessionExpiredMsg();
          throw Error("Auth Error. Abort.");
        }
        popupBox.showErrorMsg(
          "Failed to fetch the token. Please check your network and try again."
        );
        throw Error("Token Error. Abort.");
      }

      this.policyInfo.policy = authResult.body.policy;
      this.policyInfo.bankHolderAcceptance = authResult.body.bankHolderAcceptance;

      RequestBuilder.setAuthToken(authResult.body.token);

      const targetApplicant = authResult.body.insuredPerson;

      const consentQuestionsResult = await InsuranceClient.getConsentQuestions(
        "InsuranceWebPayment"
      );
      if (consentQuestionsResult.statusCode != 200) {
        popupBox.showErrorMsg(
          "We cannot find your consent question record. Please try again or contact us."
        );
        throw Error("Auth Error. Abort.");
      }
      this.policyInfo.seperateBankHolder = consentQuestionsResult.body[0].value == "No";

      // PolicyBenefits in xapi only returns ones waiting for confirmation.
      // There should only be one batch at a time so they'd all have same quircpolicyrefid
      const targetPolicyRefId =
        this.policyInfo.policy.policyBenefits[0]?.quircPolicy.quircPolicyRefId;

      // Force refresh if the policy is different
      // This will clear all the cache
      if (
        this.policyInfo.insuredPerson?.id &&
        this.policyInfo.insuredPerson.id.length > 0 &&
        this.policyInfo.insuredPerson.id != targetApplicant?.id &&
        this.policyInfo.policyRefId &&
        this.policyInfo.policyRefId.length > 0 &&
        this.policyInfo.policyRefId != targetPolicyRefId
      ) {
        router.go(0);
      }

      this.policyInfo.insuredPerson = targetApplicant;
      this.policyInfo.policyRefId = targetPolicyRefId;

      if (process.env.VUE_APP_LOG_LEVEL > 0) {
        console.log(this.policyInfo);
      }
    },
  },
});
