
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { insuranceStore } from "@/stores/InsuranceStore";

export default defineComponent({
  name: "SsliConfirmationTab",
  computed: {
    ...mapState(insuranceStore, ["policyInfo"]),
  },
});
