import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3787f684"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["summary-card p-3", {'bg-light-gray' : _ctx.colour === 'gray', 'bg-blue text-white' : _ctx.colour === 'blue', 'fixed-max-width' : !_ctx.isMobileScreen, 'w-100' : _ctx.isMobileScreen}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["item-tertiary-title mb-3", {'text-white' : _ctx.colour === 'blue'}])
    }, _toDisplayString(_ctx.title), 3),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["justify-content-between", _ctx.colour !== 'blue' && _ctx.isMobileScreen? 'col-12': 'd-flex flex-row'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["item-label", {'text-white' : _ctx.colour === 'blue', 'col-sm-12 col-xs-12': _ctx.colour !== 'blue' && _ctx.isMobileScreen}])
          }, _toDisplayString(item.text), 3),
          _createElementVNode("div", {
            class: _normalizeClass({'text-white' : _ctx.colour === 'blue', 'col-sm-12 col-xs-12': _ctx.colour !== 'blue' && _ctx.isMobileScreen})
          }, _toDisplayString(item.value), 3)
        ], 2))
      }), 128))
    ])
  ], 2))
}