import InsuranceClient from "@/rest-client/InsuranceClient";
import { popupStore } from "@/stores/PopupStore";

export default class InsuranceQuestionnaire {
  static questionnaireData = {};
  static sectionRelationshipMap = {};
  currentTabId;
  configs;
  popupBox;

  constructor(configs, data, sectionRelationshipMap) {
    this.configs = configs;
    InsuranceQuestionnaire.questionnaireData = data;
    InsuranceQuestionnaire.sectionRelationshipMap = sectionRelationshipMap;
    this.popupBox = popupStore();
  }

  static async getConditions(questionExtRefId) {
    let conditionsResponse = await InsuranceClient.getConditions(questionExtRefId);
    if (conditionsResponse.statusCode != 200) {
      this.popupBox.showErrorMsg("Failed to fetch the conditions");
    }

    let conditions = conditionsResponse.body.data;
    return conditions.map((condition) => {
      return {
        text: condition.title,
        value: condition.id.toString(),
        sequence: condition.sequence,
      };
    });
  }

  getNextPath() {
    let sectionKeys = Object.keys(InsuranceQuestionnaire.sectionRelationshipMap);
    let currentTabIndex = sectionKeys.indexOf(this.currentTabId);

    if (currentTabIndex < sectionKeys.length - 1) {
      let nextPagePath =
        InsuranceQuestionnaire.sectionRelationshipMap[sectionKeys[currentTabIndex + 1]].path;
      return nextPagePath;
    }

    return null;
  }

  getPreviousPath() {
    let sectionKeys = Object.keys(InsuranceQuestionnaire.sectionRelationshipMap);
    let currentTabIndex = sectionKeys.indexOf(this.currentTabId);

    if (currentTabIndex > 0) {
      let previousPagePath =
        InsuranceQuestionnaire.sectionRelationshipMap[sectionKeys[currentTabIndex - 1]].path;
      return previousPagePath;
    }

    return null;
  }

  getDefaultPath() {
    let sectionKeys = Object.keys(InsuranceQuestionnaire.sectionRelationshipMap);
    return InsuranceQuestionnaire.sectionRelationshipMap[sectionKeys[0]].path;
  }

  getFirstSubSectionNameQueue(section) {
    let nameQueue = [];
    let firstSection = section;

    while (firstSection && firstSection.sections && Object.keys(firstSection.sections).length > 0) {
      let keys = Object.keys(firstSection.sections);
      let firstKey = keys[0];

      nameQueue.push(firstKey);
      firstSection = section.sections[firstKey];
    }

    return nameQueue;
  }

  analysePath(sectionNameQueue) {
    let currentSections = InsuranceQuestionnaire.questionnaireData;
    let questions = null;
    let title = null;
    let lastMatchingSection = null;
    for (const i in sectionNameQueue) {
      let sectionName = sectionNameQueue[i];

      if (!currentSections[sectionName]) {
        return null;
      }

      questions = currentSections[sectionName].questions;
      title = currentSections[sectionName].title;
      lastMatchingSection = currentSections[sectionName];
      currentSections = currentSections[sectionName].sections;
    }

    return {
      questions,
      title,
      lastMatchingSection,
    };
  }

  async getQuestionsByPath(path) {
    let sectionNameQueue = path.split("/");

    if (!sectionNameQueue) {
      return null;
    }

    sectionNameQueue = sectionNameQueue.filter((section) => section.length > 0);

    if (sectionNameQueue.length < 1) {
      return null;
    }

    let result = this.analysePath(sectionNameQueue);
    if (!result) {
      return null;
    }

    let { questions, title, lastMatchingSection } = result;

    // if the path didn't match a section, no need to continue
    if (!lastMatchingSection) {
      return null;
    }

    // if no questions fetched, but got a section, try fetch for the first sub path
    if (!questions || questions.length < 1) {
      let subSectionNameQueue = this.getFirstSubSectionNameQueue(lastMatchingSection);

      if (subSectionNameQueue && subSectionNameQueue.length > 0) {
        sectionNameQueue = sectionNameQueue.concat(subSectionNameQueue);

        let result = this.analysePath(sectionNameQueue);
        questions = result.questions;
        title = result.title;

        // if still no result after retry the first sub path, return null
        if (!result) {
          return null;
        }
      }
    }

    // Sort the questions by sequence number
    questions = questions.sort((a, b) => a.questionSequence - b.questionSequence);

    this.currentTabId = sectionNameQueue[sectionNameQueue.length - 1];

    let questionVMs = {
      title,
      currentTabId: this.currentTabId,
      questions: await this.mapQuestionsDataToQuestionViewModel(questions),
    };

    return questionVMs;
  }

  async mapQuestionsDataToQuestionViewModel(questions) {
    let questionVMs = await Promise.all(
      questions.map(async (question) => {
        let questionVM = {
          key: question.externalReferenceId,
          title: question.title,
          text: question.text,
          value: question.value,
          prompt: question.prompt,
          seq: question.questionSequence,
          answerRequired: question.answerRequired,
          allAnswersRequired: question.allAnswersRequired,
          allowMultiAnswers: question.allowMultiAnswers,
          disable: false,
        };

        if (this.configs?.additionalInfo[question.externalReferenceId]) {
          questionVM.additionalInfo = this.configs.additionalInfo[question.externalReferenceId];
        }

        if (question.answer && question.answer.length > 0) {
          // Sort answers by sequence number
          question.answer = question.answer.sort((a, b) => a.answerSequence - b.answerSequence);

          questionVM.answerType = question.answer[0].answerType;
          questionVM.dataType = question.answer[0].dataType;
          questionVM.options = question.answer.map((answer) => ({
            value: answer.value,
            text: answer.text,
            answerType: answer.answerType,
            dataType: answer.dataType,
            isOverrideAnswer: answer.overrideAnswer,
          }));

          questionVM.overrideAnswers = questionVM.options.filter(
            (option) => option.isOverrideAnswer
          );

          // Get conditions for dropdown
          if (
            questionVM.answerType == "SelectionAnswerProvider" ||
            questionVM.answerType == "MultipleSelectionAnswerProvider"
          ) {
            questionVM.conditions = await InsuranceQuestionnaire.getConditions(
              question.externalReferenceId
            );
          }
        }

        return questionVM;
      })
    );

    return questionVMs;
  }
}
