
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "BMICalculationItemWeight",
  props: {
    title: {type: String, default: ""},
    prompt: {type: String, default: ""},
    value: {type: [String, Number, null] as PropType<String | number | null>, default: null},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    isSmallScreen: {type: Boolean, default: false}
  },
  emits: ["update:value"],
  data() {
    return {
      units: [
        {
          value: "Kg",
          text: "kg",
        },
        {
          value: "Lb",
          text: "lb",
        },
      ],
      selectedUnit: "Kg",
      returnedKilograms: this.value == null ? null : Number(this.value),
      kilograms: this.value == 0 ? null : this.value == null ? null : Number(this.value),
      pounds: 0,
      weightUnknown: false,
      inputDisable: false,
    };
  },
  watch: {
    weightUnknown(newVal) {
      this.returnedKilograms = null;
      this.inputDisable = newVal;
      this.emitKilograms();
    },
    kilograms() {
      this.returnedKilograms = this.kilograms;
      this.emitKilograms();
    },
    pounds() {
      this.returnedKilograms = this.pounds * 0.453592;
      this.emitKilograms();
    },
  },
  mounted() {
    if (this.value == 0) {
      this.weightUnknown = true;
    }
    if (this.value == null) {
      this.kilograms = null;
    }
  },
  methods: {
    clickEventHandler(unit:any) {
      if (this.selectedUnit == unit.value) {
        return;
      }
      this.selectedUnit = unit.value;
      if (this.returnedKilograms == null) {
        return;
      }
      if (isNaN(this.returnedKilograms)) {
        this.kilograms = null;
        this.returnedKilograms = null;
        this.pounds = 0;
        return;
      }
      if (this.selectedUnit == "Kg") {
        this.kilograms = Math.round(this.returnedKilograms * 100) / 100;
      } else if (this.selectedUnit == "Lb") {
        this.pounds = Math.round(this.returnedKilograms / 0.00453592) / 100;
      }
      this.emitKilograms();
    },
    emitKilograms() {
      this.returnedKilograms = this.returnedKilograms == null ? null : Math.round(this.returnedKilograms * 100) / 100;
      this.$emit("update:value", this.weightUnknown ? "0" : this.returnedKilograms?.toString());
    },
  },
});
