
import { PropType, defineComponent } from "vue";
import { AgreementOptions } from "@/interfaces/AgreementOptions";

export default defineComponent({
  name: "AgreementCard",
  props: {
    content: {type: String, default: ""},
    value: {type: String, default: ""},
    status: {type: String, default: ""},
    option: {type:  Object as PropType<AgreementOptions>, default: () => {}},
    validationError: {type: String, default: ""},
    isDisabled: {type: Boolean, default: false},
    isSmallScreen: {type: Boolean, default: false}
  },
  emits: ["update:value"],
  data() {
    return {
      internalValue: this.value == this.option?.value,
    };
  },
  watch: {
    internalValue() {
      this.$emit("update:value", this.internalValue ? this.option?.value : "");
    },
  },
});
