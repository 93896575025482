export enum FrequencyDivider {
  WEEKLY = 52.143,
  FORTNIGHTLY = 26.071,
  MONTHLY = 12,
  ANNUALLY = 1,
}

export enum PaymentSummaryFrequencyDivider {
  weekly = 52.143,
  fortnightly = 26.071,
  monthly = 12,
  yearly = 1,
}
