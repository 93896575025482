
import BoosterNav from "@/components/booster-nav/BoosterNav.vue";
import PopupBox from '@/components/popup-box/PopupBox.vue';
import { popupStore } from '@/stores/PopupStore';
import { mapState } from 'pinia';
import { ScreenWidth } from "./enums/ScreenWidth.enum";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    BoosterNav,
    PopupBox,
  },
  data() {
    return {
      screenWidth: ScreenWidth.XLarge,
      ScreenWidth: ScreenWidth,
    }
  },
  computed: {
    ...mapState(popupStore, ['popupState']),
  },
  async mounted() {
    window.addEventListener("resize", this.widthChangeHandler);
    this.widthChangeHandler();
  },
  unmounted() {
    window.removeEventListener("resize", this.widthChangeHandler);
  },
  methods: {
    widthChangeHandler() {
      if(window.screen.width <= 575) {
        this.screenWidth = ScreenWidth.Mobile;
      }

      if(window.screen.width > 575 && window.screen.width <= 767) {
        this.screenWidth = ScreenWidth.Small;
      }

      if(window.screen.width > 767 && window.screen.width <= 927) {
        this.screenWidth = ScreenWidth.Medium;
      }

      if(window.screen.width > 927 && window.screen.width <= 1245) {
        this.screenWidth = ScreenWidth.Large;
      }

      if(window.screen.width > 1245 && window.screen.width <= 1400) {
        this.screenWidth = ScreenWidth.Wide;
      }

      // Default is XLarge
    },
  }
});
