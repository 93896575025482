import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fcaee0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "item-tertiary-title d-flex justify-content-between py-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCollapse()))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["smaller-text", {'item-booster-blue': !_ctx.isCollapsed}])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("i", {
        class: _normalizeClass(["bi bi-chevron-down icon", {'d-none': !_ctx.isCollapsed}])
      }, null, 2),
      _createElementVNode("i", {
        class: _normalizeClass(["bi bi-chevron-up icon", {'d-none': _ctx.isCollapsed}])
      }, null, 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["pt-2 pb-3", {'collapsed': _ctx.isCollapsed}])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}