export const dataGeneratorConfigs = {
  hiddenSections: {
    prefixes: ["DND_"], // the external reference id prefix of the sections need to be hidden.
    names: ["Details_from_quote", "Before_we_begin"], // The external reference id of the sections need to be hidden.
  },
  hiddenSectionTitles: {
    prefixes: ["DNDT_"], // the external reference id prefix to indicate hiding the section title
  },
  hiddenQuestions: {
    prefixes: ["DND_"],
  },
  boosterPageUrlPrefix: "AfterQuestionnaire",
  topLevelSections: [
    {
      name: "About you",
      prefix: "About_you",
      sequence: 1,
      tab: "General",
      static: false,
    },
    {
      name: "Medical",
      prefix: "Medical",
      sequence: 2,
      tab: "General",
      static: false,
    },
    {
      name: "Product and quote",
      prefix: "ProductQuote",
      sequence: 3,
      tab: "ProductAmendments",
      static: true,
    },
    {
      name: "Contact details",
      prefix: "ContactDetails",
      sequence: 4,
      tab: "ContactDetails",
      static: true,
    },
    {
      name: "Policy ownership",
      prefix: "PolicyOwnership",
      sequence: 5,
      tab: "PolicyOwnership",
      static: true,
    },
    {
      name: "Payment",
      prefix: "Payment",
      sequence: 6,
      tab: "Payment",
      static: true,
    },
    {
      name: "Confirmation",
      prefix: "Confirmation",
      sequence: 7,
      tab: "Confirmation",
      static: true,
    },
  ],
  additionalInfo: {
    Annual_income: {
      icon: "bi bi-currency-dollar",
    },
    Annual_income_other_occupation: {
      icon: "bi bi-currency-dollar",
    },
    Existing_life_cover: {
      icon: "bi bi-currency-dollar",
    },
    Existing_TPD_cover: {
      icon: "bi bi-currency-dollar",
    },
    Existing_IP_cover: {
      icon: "bi bi-currency-dollar",
    },
    Existing_CI_cover: {
      icon: "bi bi-currency-dollar",
    },
  },
};
