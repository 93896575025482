import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bf7d4cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal d-block modal-background",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header border-0 align-items-start pb-0" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "modal-body p-4 pt-2 pb-3" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "modal-footer justify-content-center border-0 pt-0 mb-3" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showPopupBox)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-dialog popup-style", _ctx.isMobileScreen ? 'ps-2 pe-4': ''])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", {
                class: "modal-title item-secondary-title text-center w-100 pt-3",
                innerHTML: _ctx.title
              }, null, 8, _hoisted_4),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close btn-dismiss btn-round",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopupBoxOnSecondaryBtn && _ctx.closePopupBoxOnSecondaryBtn(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "text-center item-secondary-title item-normal",
                innerHTML: _ctx.content
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.secondaryText)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: _normalizeClass(["btn btn-booster-secondary btn-foot float-start", {'col': _ctx.isMobileScreen && _ctx.secondaryText}]),
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closePopupBoxOnSecondaryBtn && _ctx.closePopupBoxOnSecondaryBtn(...args))),
                    innerHTML: _ctx.secondaryText
                  }, null, 10, _hoisted_8))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-booster-primary btn-foot", {'col': _ctx.isMobileScreen && _ctx.secondaryText, 'float-end': _ctx.secondaryText}]),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closePopupBox && _ctx.closePopupBox(...args))),
                innerHTML: _ctx.okay
              }, null, 10, _hoisted_9)
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}