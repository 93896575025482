/// Used for date calculations
export default class DateCalculationUtility {
  static getDifferenceBetweenTwoDates(dateString1: string, dateString2: string) {
    const date1 = new Date(new Date(dateString1).setHours(0, 0, 0, 0));
    const date2 = new Date(new Date(dateString2).setHours(0, 0, 0, 0));
    const differenceInTime = date2.getTime() - date1.getTime();

    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  }
}
